export const DEFAULT_TASK_MATERIAL_ICON = "playlist_add_check";
export const DEFAULT_TASK_SYMBOL_ID = "___NOT_YET_GIVEN___";

export enum LS_KEY {
  TermsOfServiceAccepted = "TermsOfServiceAccepted",
}

export enum TaskType {
  normal = 0,
  legacyRecurringInstance = 1,
  setup1 = 11,
  setup2 = 12,
  setup3 = 13,
  setup4 = 14,
  onboarding1 = 21,
  onboarding2 = 22,
  onboarding3 = 23,
  recurringMorning = 31,
  recurringNight = 32,
}

export const DEFAULT_TASK_DURATION = 15;
