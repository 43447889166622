export const isUniqueById = (arr: { id: string | number }[]): boolean => {
  const uniqueIds = new Set<number | string>();
  for (const obj of arr) {
    if (uniqueIds.has(obj.id)) {
      return false;
    }
    uniqueIds.add(obj.id);
  }
  return true;
};
