const COLOR_MAP = {
  day: "#f78d86",
  night: "#5e95ca",
  nature: "#69a759",
  // TODO right color
  classic: "#ccc",
} as const;

// here to replace special color values form mac
export const getColor = (color: string): string => {
  return (COLOR_MAP[color] as string) ?? color;
};
