import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RootState } from "../store";
import { getDB } from "../../rxdb/db";
import { type RxTaskNaked } from "../../rxdb/task.schema";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import { useSelector } from "react-redux";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { dirtyDeepCopy } from "../../util/dirtyDeepCopy";
import { type TimelineEntry } from "../../features/DayView/Timeline/timeline.model";
import { useRecurringCfgsForDayWithoutDetached } from "../recurring/recurringSlice";
import { generateTimelineEntries } from "../../features/DayView/Timeline/generateTimelineEntries";
import { getFirstFreePeriodStart } from "../../util/getFirstFreePeriodStart";

export interface Task extends RxTaskNaked {}

export interface TaskState {
  tasks: Task[];
}

const initialState: TaskState = {
  tasks: [],
};

const taskModelClean = <T extends Partial<Task>>(
  originalTaskOrPartial: T
): T => {
  const tCopy = dirtyDeepCopy(originalTaskOrPartial);
  // make sure order index is always null when start_time was set
  if (tCopy.start_time) {
    tCopy.order_index = null;
  }
  if (tCopy.subtasks) {
    tCopy.subtasks = tCopy.subtasks.filter(
      (st) => st.title && st.title.trim().length > 1
    );
    if (!tCopy.subtasks) {
      tCopy.subtasks = [];
    }
  }
  if (tCopy.is_all_day) {
    tCopy.start_time = null;
  }
  if (tCopy.is_in_inbox) {
    tCopy.day = null;
    tCopy.start_time = null;
    tCopy.is_all_day = false;
  }

  return tCopy;
};

export const addTaskRxDb = createAsyncThunk(
  "taskOrRecurring/addTaskRxDb",
  async (task: RxTaskNaked) => {
    const db = await getDB();

    try {
      await db.task.insert(taskModelClean(task));
    } catch (e) {
      console.error(e);
    }
  }
);

export const removeTaskRxDb = createAsyncThunk(
  "taskOrRecurring/removeTaskRxDb",
  async (id: string) => {
    const db = await getDB();
    try {
      const doc = await db.task.findOne(id).exec();
      if (doc) {
        try {
          await doc.remove();
        } catch (err) {
          console.error("could not remove doc");
          console.dir(err);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const updateTaskRxDb = createAsyncThunk(
  "taskOrRecurring/updateTaskRxDb",
  async ({ id, changes }: { id: string; changes: Partial<Task> }) => {
    const db = await getDB();
    try {
      const doc = await db.task.findOne(id).exec();
      if (doc) {
        try {
          await doc.incrementalPatch(
            taskModelClean({
              ...changes,
              // important to set
              modified_at: getDbStrDateFull(),
            })
          );
        } catch (err) {
          console.error("could not update doc");
          console.dir(err);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTasks: (state, action: PayloadAction<Task[]>) => {
      state.tasks = action.payload;
    },
  },
  // extraReducers: (builder) => {},
});

export const { setTasks } = taskSlice.actions;

export const selectTasks = (state: RootState): Task[] => state.task.tasks;
export const selectInboxTasks = (state: RootState): Task[] => {
  return state.task.tasks
    .filter((task) => task.is_in_inbox)
    .sort(
      (taskA, taskB) => (taskA.order_index ?? 0) - (taskB.order_index ?? 0)
    );
};

export const selectUndoneInboxTasks = (state: RootState): Task[] => {
  return selectInboxTasks(state).filter((task) => !task.completed_at);
};

export const selectDoneInboxTasks = (state: RootState): Task[] => {
  return selectInboxTasks(state).filter((task) => !!task.completed_at);
};

export const selectTasksForDay = (state: RootState, date: Date): Task[] => {
  const dateStr = getIsoStrDateOnly(date);

  return state.task.tasks
    .filter((task) => task.day === dateStr)
    .sort((taskA, taskB) => (taskA.start_time ?? 0) - (taskB.start_time ?? 0));
};

export const useTasksForDay = (dayDate: Date) => {
  return useSelector((state: RootState): Task[] => {
    return selectTasksForDay(state, dayDate);
  });
};

export const useTaskById = (taskId: string) => {
  return useSelector((state: RootState): Task => {
    const t = state.task.tasks.find((tI) => tI.id === taskId);
    if (!t) {
      throw new Error("Could not find task");
    }
    return t;
  });
};

export const useTimelineEntriesForDay = (
  dayDate: Date,
  now: Date
): TimelineEntry[] => {
  const tasksForDay = useTasksForDay(dayDate);
  const recurringCfgsForDay = useRecurringCfgsForDayWithoutDetached(dayDate);
  const allTasksForDay = [...tasksForDay, ...recurringCfgsForDay];
  return generateTimelineEntries(allTasksForDay, dayDate, now);
};

export const useFirstFreePeriodStartForDay = (
  dayDate: Date,
  now: Date,
  minDuration: number
): string => {
  const timelineEntries: TimelineEntry[] = useTimelineEntriesForDay(
    dayDate,
    now
  );
  return getFirstFreePeriodStart(timelineEntries, minDuration, dayDate, now);
};

export default taskSlice.reducer;
