import { useState } from "react";
import { useSelector } from "react-redux";
import { strTimeToDbFloat } from "../../util/strTimeToDbFloat";
import styles from "./OnboardingQuestions.module.scss";
import { addRecurringRxDb } from "../../store/recurring/recurringSlice";
import {
  ONBOARDING_TASK_SLEEP,
  ONBOARDING_TASK_WAKE_UP,
} from "./onboardingTasks.const";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import { selectUserIdOrError } from "../../store/session/sessionSlice";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import { useAppDispatch } from "../../shared/hooks";

export const OnboardingQuestions = ({
  onComplete,
}: {
  onComplete: ({
    wakeupTime,
    sleepTime,
  }: {
    wakeupTime: number;
    sleepTime: number;
  }) => void;
}) => {
  const [step, setStep] = useState(0);
  const [wakeupTime, setWakeupTime] = useState<string>("08:00");
  const [sleepTime, setSleepTime] = useState<string>("22:00");
  const dispatch = useAppDispatch();
  const userId = useSelector(selectUserIdOrError);

  switch (step) {
    case 0:
      return (
        <>
          <h1>
            Structured as a <em>Day Planner</em>
          </h1>
          <p>Bring structure into your day</p>

          <p>
            You don`t need to create an account and the setup only takes one
            minute.
          </p>
          <button className="btn" onClick={() => setStep(step + 1)}>
            Get Started
          </button>
        </>
      );

    case 1:
      return (
        <>
          <h1>
            A <em>Timeline</em> of your day
          </h1>
          <p>All your tasks in a visual timeline.</p>

          <button className="btn" onClick={() => setStep(step + 1)}>
            Continue
          </button>
        </>
      );

    case 2:
      return (
        <>
          <h1>
            When do you <em>wake up</em>?
          </h1>

          <div className={styles.inputWrapper}>
            <input
              type="time"
              value={wakeupTime}
              onChange={(ev) => setWakeupTime(ev.target.value)}
            />
          </div>

          <button
            className="btn"
            disabled={!wakeupTime}
            onClick={() => setStep(step + 1)}
          >
            Continue
          </button>
        </>
      );

    case 3:
      return (
        <>
          <h1>
            What time do you <em>sleep</em>?
          </h1>

          <div className={styles.inputWrapper}>
            <input
              type="time"
              value={sleepTime}
              onChange={(ev) => setSleepTime(ev.target.value)}
            />
          </div>

          <button
            className="btn"
            disabled={!sleepTime}
            onClick={() => {
              if (!wakeupTime || !sleepTime) {
                console.error("No wakeup or sleep time");
                return;
              }
              const parsed = {
                wakeupTime: strTimeToDbFloat(wakeupTime),
                sleepTime: strTimeToDbFloat(sleepTime),
              };

              void dispatch(
                addRecurringRxDb({
                  ...ONBOARDING_TASK_WAKE_UP,
                  id: uuid(),
                  user_id: userId,
                  start_time: parsed.wakeupTime,
                  modified_at: getDbStrDateFull(),
                  created_at: getDbStrDateFull(),
                  start_day: getIsoStrDateOnly(new Date()),
                })
              );

              void dispatch(
                addRecurringRxDb({
                  ...ONBOARDING_TASK_SLEEP,
                  id: uuid(),
                  user_id: userId,
                  start_time: parsed.sleepTime,
                  modified_at: getDbStrDateFull(),
                  created_at: getDbStrDateFull(),
                  start_day: getIsoStrDateOnly(new Date()),
                })
              );

              onComplete(parsed);
            }}
          >
            Continue
          </button>
        </>
      );

    default:
      return <div></div>;
  }
};
