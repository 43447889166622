import { type Task } from "../../../store/task/taskSlice";
import styles from "./TaskInboxTask.module.scss";
import { getColor } from "../../../util/getColor";
import { Ico } from "../../../ui-components/Ico";
import { getMaterialIdFromSymbolId } from "../../../util/getMaterialIdFromSymbolId";
import { DEFAULT_TASK_MATERIAL_ICON } from "../../../shared/app.const";
import classNames from "classnames";
import { humanizeDuration } from "../../../util/humanizeDuration";

export const TaskInboxTask = (props: {
  task: Task;
  onAddClick: () => void;
  onContextModalClick: () => void;
}) => {
  const task = props.task;
  const subtasks = task.subtasks ?? [];
  const nrOfDoneSubtasks = subtasks.filter((s) => s.completed_at).length;

  return (
    <div
      className={classNames(styles.TaskInboxTask, {
        [styles.isCompleted]: !!task.completed_at,
      })}
      style={
        {
          "--task-color": getColor(task.color),
        } as any
      }
    >
      <div className={classNames(styles.taskIcon)}>
        <Ico
          name={
            getMaterialIdFromSymbolId(task.symbol) ?? DEFAULT_TASK_MATERIAL_ICON
          }
          className="iContrast"
        />
      </div>

      <div
        className={styles.taskMiddleSection}
        onClick={() => props.onContextModalClick()}
      >
        {task.duration && task.duration > 1 && (
          <div
            title={JSON.stringify(task, null, 2)}
            className={styles.taskTime}
          >
            {humanizeDuration(task.duration)}
          </div>
        )}

        <div className={styles.taskTitle}>{task.title}</div>
        {subtasks.length > 0 && (
          <div className={styles.taskSubtask}>
            <Ico name="check_box" className="izI" /> {nrOfDoneSubtasks}/
            {subtasks.length}
          </div>
        )}
      </div>

      <button className="btn-ico" onClick={props.onAddClick}>
        <Ico name="add" />
      </button>
    </div>
  );
};
