import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RootState } from "../store";
import { getDB } from "../../rxdb/db";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import { useSelector } from "react-redux";
import { type RxRecurringOccurrenceNaked } from "../../rxdb/recurringOccurrence.schema";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";

export interface RecurringState {
  recurringOccurrences: RxRecurringOccurrenceNaked[];
}

const initialState: RecurringState = {
  recurringOccurrences: [],
};

const recurringOccurrenceModelClean = <
  T extends Partial<RxRecurringOccurrenceNaked>
>(
  originalRecurringOrPartial: T
): T => {
  return originalRecurringOrPartial;
};

export const addRecurringOccurrenceRxDb = createAsyncThunk(
  "recurringOccurrence/addRecurringOccurrenceRxDb",
  async (recurringOccurrence: RxRecurringOccurrenceNaked) => {
    const db = await getDB();

    try {
      await db.recurring_occurrence.insert(
        recurringOccurrenceModelClean(recurringOccurrence)
      );
    } catch (e) {
      console.error(e);
    }
  }
);

export const removeRecurringOccurrenceRxDb = createAsyncThunk(
  "recurringOccurrence/removeRecurringRxDb",
  async (id: string) => {
    const db = await getDB();
    try {
      const doc = await db.recurring_occurrence.findOne(id).exec();
      if (doc) {
        try {
          await doc.remove();
        } catch (err) {
          console.error("could not remove doc");
          console.dir(err);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const removeRecurringOccurrencesRxDb = createAsyncThunk(
  "recurringOccurrences/removeRecurringRxDb",
  async (ids: string[]) => {
    const db = await getDB();
    try {
      await db.recurring_occurrence.bulkRemove(ids);
    } catch (e) {
      console.error(e);
    }
  }
);

export const updateRecurringOccurrenceRxDb = createAsyncThunk(
  "recurringOccurrence/updateRecurringOccurrenceRxDb",
  async ({
    id,
    changes,
  }: {
    id: string;
    changes: Partial<RxRecurringOccurrenceNaked>;
  }) => {
    const db = await getDB();
    try {
      const doc = await db.recurring_occurrence.findOne(id).exec();
      if (doc) {
        try {
          await doc.incrementalPatch(
            recurringOccurrenceModelClean({
              ...changes,
              // important to set
              modified_at: getDbStrDateFull(),
            })
          );
        } catch (err) {
          console.error("could not update doc");
          console.dir(err);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const recurringOccurrenceOccurrenceSlice = createSlice({
  name: "recurringOccurrence",
  initialState,
  reducers: {
    setRecurringOccurrences: (
      state,
      action: PayloadAction<RxRecurringOccurrenceNaked[]>
    ) => {
      state.recurringOccurrences = action.payload;
    },
  },
  // extraReducers: (builder) => {},
});

export const { setRecurringOccurrences } =
  recurringOccurrenceOccurrenceSlice.actions;

export const selectRecurringOccurrences = (
  state: RootState
): RxRecurringOccurrenceNaked[] =>
  state.recurringOccurrence.recurringOccurrences;

export const selectRecurringOccurrencesForDay = (
  state: RootState,
  date: Date
): RxRecurringOccurrenceNaked[] => {
  const dateStr = getIsoStrDateOnly(date);

  return state.recurringOccurrence.recurringOccurrences.filter(
    (recurringOccurrence) => recurringOccurrence.day === dateStr
  );
};

export const useRecurringOccurrenceForDayByRecurringId = (
  dayDate: Date,
  recurringId
) => {
  return useSelector((state: RootState): RxRecurringOccurrenceNaked | null => {
    return (
      selectRecurringOccurrencesForDay(state, dayDate).find(
        (occurrence) => occurrence.recurring === recurringId
      ) ?? null
    );
  });
};

export const useRecurringOccurrencesByRecurringId = (recurringId) => {
  return useSelector((state: RootState): RxRecurringOccurrenceNaked[] => {
    return state.recurringOccurrence.recurringOccurrences.filter(
      (occurrence) => occurrence.recurring === recurringId
    );
  });
};

export const useRecurringOccurrencesForDay = (dayDate: Date) => {
  return useSelector((state: RootState): RxRecurringOccurrenceNaked[] => {
    return selectRecurringOccurrencesForDay(state, dayDate);
  });
};

export const useRecurringOccurrenceById = (recurringOccurrenceId: string) => {
  return useSelector((state: RootState): RxRecurringOccurrenceNaked => {
    const r = state.recurringOccurrence.recurringOccurrences.find(
      (rI) => rI.id === recurringOccurrenceId
    );
    if (!r) {
      throw new Error("Could not find recurringOccurrence");
    }
    return r;
  });
};

export default recurringOccurrenceOccurrenceSlice.reducer;
