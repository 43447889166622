import { type RxTaskNaked } from "../../../rxdb/task.schema";
import { type RxRecurringNaked } from "../../../rxdb/recurring.schema";

export enum TimelineEntryType {
  TASK = "TASK",
  ADD_TASK_PROMPT = "ADD_TASK_PROMPT",
  GAP_MSG = "GAP_MSG",
}

interface TimelineEntryCommon {
  type: TimelineEntryType;
  durationInMin: number;
  start: Date;
  end: Date;
  progress: number;
  color: string | null;
}

export interface TimelineTaskEntry extends TimelineEntryCommon {
  type: TimelineEntryType.TASK;
  taskOrRecurring: RxTaskNaked | RxRecurringNaked;
  isOverlapBefore: boolean;
  isOverlapAfter: boolean;
}

export interface TimelineAddTaskPromptEntry extends TimelineEntryCommon {
  type: TimelineEntryType.ADD_TASK_PROMPT;
  durationInMin: number;
}

export interface TimelineGapMsgEntry extends TimelineEntryCommon {
  type: TimelineEntryType.GAP_MSG;
  durationInMin: number;
  isInThePast: boolean;
}

export type TimelineEntry =
  | TimelineTaskEntry
  | TimelineAddTaskPromptEntry
  | TimelineGapMsgEntry;
