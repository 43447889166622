import { type RxCollection } from "rxdb";
import { type RxTaskNaked } from "./task.schema";
import { type Database } from "../api/supabase.types";
import { type RxRecurringNaked } from "./recurring.schema";
import { type RxRecurringOccurrenceNaked } from "./recurringOccurrence.schema";

export type ReplicationFields =
  | "_deleted"
  | "replication_revision"
  | "received_at";

export interface RxGenericReplicationDocumentNaked {
  id: string;
  user_id: string;
  replication_revision?: string;
  received_at?: string;
}

// TASK
export type DbTask = Database["public"]["Tables"]["task"]["Row"];
// eslint-disable-next-line @typescript-eslint/ban-types
type RxTaskCollection = RxCollection<RxTaskNaked, {}, {}, {}>;

// RECURRING
export type DbRecurring = Database["public"]["Tables"]["recurring"]["Row"];
// eslint-disable-next-line @typescript-eslint/ban-types
type RxRecurringCollection = RxCollection<RxRecurringNaked, {}, {}, {}>;

// RECURRING OCCURRENCE
export type DbRecurringOccurrence =
  Database["public"]["Tables"]["recurring_occurrence"]["Row"];
// eslint-disable-next-line @typescript-eslint/ban-types
type RxRecurringOccurrenceCollection = RxCollection<
  RxRecurringOccurrenceNaked,
  {},
  {},
  {}
>;

export type RxAllCollections = {
  task: RxTaskCollection;
  recurring: RxRecurringCollection;
  recurring_occurrence: RxRecurringOccurrenceCollection;
};
export const RECEIVED_AT_FIELD = "received_at";

export type CheckpointType = {
  name: string;
  checkpointUpdate: number;
};

export interface StandardReplicationModel {
  id: string;
  replication_revision?: string;
  [RECEIVED_AT_FIELD]?: string;
}
