import { Auth as AuthSup } from "@supabase/auth-ui-react";
import { supabase } from "../../api/supabaseClient";
import { ThemeSupa } from "@supabase/auth-ui-shared";

// @see: https://github.com/supabase/auth-ui/blob/main/examples/react/src/App.tsx
const Auth = () => (
  <AuthSup
    supabaseClient={supabase as any}
    providers={[]}
    view={"magic_link"}
    appearance={{
      theme: ThemeSupa,
      variables: {
        default: {
          colors: {
            brand: "rgb(230, 161, 154)",
            brandAccent: "darkred",
          },
        },
      },
    }}
  />
);

export default Auth;
