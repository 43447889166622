import { type RxRecurringNaked } from "../rxdb/recurring.schema";
import { type RecurringOwnFields } from "../features/TaskEditForm/taskEdit.model";

type Impossible<K extends keyof any> = {
  [P in K]: never;
};
type NoExtraProperties<T, U extends T = T> = U &
  Impossible<Exclude<keyof U, keyof T>>;

export const getRecurringOnlyFields = (recurring: RxRecurringNaked) => {
  const t: NoExtraProperties<RecurringOwnFields> = {
    recurring_type: recurring.recurring_type,
    interval: recurring.interval,
    start_day: recurring.start_day,
    end_day: recurring.end_day,
    monday: recurring.monday,
    tuesday: recurring.tuesday,
    wednesday: recurring.wednesday,
    thursday: recurring.thursday,
    friday: recurring.friday,
    saturday: recurring.saturday,
    sunday: recurring.sunday,
  };
  return t;
};
