// NOTE: start time comes as 8.5 for 8:30 AM

import { dbStartTimeAndDateStrToDate } from "./dbStartTimeAndDateStrToDate";

export const dbStartTimeAndDateToTimeStr = (
  startTime: number,
  dayDate: string,
  locale = "en-US"
): string => {
  const d = dbStartTimeAndDateStrToDate(startTime, dayDate);
  return d.toLocaleTimeString(locale, { hour: "numeric", minute: "numeric" });

  // const minutesStr = minutes.toString().padStart(2, "0");
  // return `${hours}:${minutesStr}`;
};
