import styles from "./TaskInbox.module.scss";
import {
  selectSelectedEpochDayAsDate,
  setContextModalTask,
  toggleIsShowAddTaskModal,
  toggleIsShowSidebar,
} from "../../store/ui/uiSlice";
import { Ico } from "../../ui-components/Ico";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import {
  selectDoneInboxTasks,
  selectUndoneInboxTasks,
  useFirstFreePeriodStartForDay,
} from "../../store/task/taskSlice";
import { TaskInboxTask } from "./TaskInboxTask/TaskInboxTask";
import { EditTaskModal } from "../EditTaskModal/EditTaskModal";
import { useState } from "react";
import { type RxTaskNaked } from "../../rxdb/task.schema";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { strTimeToDbFloat } from "../../util/strTimeToDbFloat";
import classNames from "classnames";
import { DEFAULT_TASK_DURATION } from "../../shared/app.const";

export const TaskInbox = () => {
  const dispatch = useAppDispatch();
  const undoneInboxTasks = useAppSelector(selectUndoneInboxTasks);
  const doneInboxTasks = useAppSelector(selectDoneInboxTasks);
  const selectedDay = useAppSelector(selectSelectedEpochDayAsDate);
  const [modalTask, setModalTask] = useState<RxTaskNaked | null>(null);
  const firstPossibleStartTime = useFirstFreePeriodStartForDay(
    selectedDay,
    new Date(),
    modalTask?.duration ?? DEFAULT_TASK_DURATION
  );

  return (
    <div className={styles.TaskInbox}>
      <button
        className="btn-ico"
        onClick={() => {
          dispatch(toggleIsShowSidebar());
        }}
      >
        <Ico name="book" />
      </button>

      <button
        className={classNames("btn-ico", styles.addInboxTaskBtn)}
        onClick={() => {
          dispatch(toggleIsShowAddTaskModal({ is_in_inbox: true }));
        }}
      >
        <Ico name="add" />
      </button>

      <h1>Inbox</h1>

      <div className={styles.undoneInboxTasks}>
        {undoneInboxTasks.map((task) => (
          <TaskInboxTask
            key={task.id}
            task={task}
            onAddClick={() => setModalTask(task)}
            onContextModalClick={() => {
              void dispatch(
                setContextModalTask({
                  taskOrRecurring: task,
                  dayTimestamp: selectedDay.getTime(),
                })
              );
            }}
          ></TaskInboxTask>
        ))}
      </div>

      {doneInboxTasks?.length > 0 && (
        <div className={styles.doneInboxTasks}>
          <h3 className={"muted"}>Done tasks</h3>

          {doneInboxTasks.map((task) => (
            <TaskInboxTask
              key={task.id}
              task={task}
              onAddClick={() => setModalTask(task)}
              onContextModalClick={() => {
                void dispatch(
                  setContextModalTask({
                    taskOrRecurring: task,
                    dayTimestamp: selectedDay.getTime(),
                  })
                );
              }}
            ></TaskInboxTask>
          ))}
        </div>
      )}
      {modalTask && (
        <EditTaskModal
          title="Add"
          taskDay={selectedDay}
          onCancel={() => setModalTask(null)}
          onSubmit={() => setModalTask(null)}
          taskOrRecurring={{
            ...modalTask,
            day: getIsoStrDateOnly(selectedDay),
            is_in_inbox: false,
            start_time: strTimeToDbFloat(firstPossibleStartTime),
          }}
        />
      )}
    </div>
  );
};
