/* Displays tasks for a given day chronologically */
import React from "react";
import { SelectDayBar } from "./SelectDayBar/SelectDayBar";
import styles from "./DayView.module.scss";
import { Timeline } from "./Timeline/Timeline";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedEpochDayAsDate,
  setSelectedEpochDay,
} from "../../store/ui/uiSlice";
import { getNrOfDaysSinceLinuxEpoch } from "../../util/getNrOfDaysSinceLinuxEpoch";
import { AllDayTasks } from "../AllDayTasks/AllDayTasks";

export const DayView = () => {
  const dayDate = useSelector(selectSelectedEpochDayAsDate);
  const dispatch = useDispatch();

  return (
    <div className={styles.DayView}>
      <SelectDayBar
        selectedDay={dayDate}
        onDaySelect={(date) =>
          dispatch(setSelectedEpochDay(getNrOfDaysSinceLinuxEpoch(date)))
        }
      />

      <div className={styles.dayViewContentOuterWrapper}>
        <div className={styles.dayViewContentWrapper}>
          <AllDayTasks day={dayDate} />
          <Timeline day={dayDate} />
        </div>
      </div>
    </div>
  );
};
