import Modal from "react-modal";

export const RecurringDeletePromptModal = (props: {
  onClose: () => void;
  onDeleteAll: () => void;
  onDeleteSingle: () => void;
  onDeleteFuture: () => void;
}) => {
  return (
    <Modal
      isOpen={true}
      className="A"
      onRequestClose={() => props.onClose()}
      shouldCloseOnOverlayClick={true}
    >
      <p>This is a recurring task.</p>
      <button type="button" className="btn-neutral" onClick={props.onDeleteAll}>
        Delete all
      </button>
      <button
        type="button"
        className="btn-neutral"
        onClick={props.onDeleteFuture}
      >
        Delete all future tasks
      </button>
      <button
        type="button"
        className="btn-neutral"
        onClick={props.onDeleteSingle}
      >
        Delete this instance only
      </button>
    </Modal>
  );
};
