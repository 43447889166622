import {
  addRxPlugin,
  createRevision,
  createRxDatabase,
  parseRevision,
  type RxStorage,
} from "rxdb";

import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";

import { wrappedValidateAjvStorage } from "rxdb/plugins/validate-ajv";
import { type RxTaskNaked, TASK_SCHEMA } from "./task.schema";

import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { type RxDatabase } from "rxdb/dist/types/types";
import { createConflictHandler } from "./conflictHandler";
import { type RxAllCollections } from "./rxdb.model";
import { RECURRING_SCHEMA, type RxRecurringNaked } from "./recurring.schema";
import { RECURRING_OCCURRENCE_SCHEMA } from "./recurringOccurrence.schema";

addRxPlugin(RxDBDevModePlugin); // TODO only add this in dev mode
addRxPlugin(RxDBLeaderElectionPlugin);

let dbPromise: Promise<RxDatabase<RxAllCollections, any, any>> | null = null;

const DB_PREFIX = "structured-web-app-db";

export const getDB = async (): Promise<
  RxDatabase<RxAllCollections, any, any>
> => {
  if (!dbPromise) dbPromise = createDatabase();
  return await dbPromise;
};

export async function createDatabase(): Promise<
  RxDatabase<RxAllCollections, any, any>
> {
  const db = await createRxDatabase<RxAllCollections>({
    name: getDatabaseName(),
    storage: wrappedValidateAjvStorage({
      storage: getStorage(),
    }),
    multiInstance: true,
  });
  await db.addCollections({
    task: {
      schema: TASK_SCHEMA,
      conflictHandler: createConflictHandler<RxTaskNaked>(),
    },
    recurring: {
      schema: RECURRING_SCHEMA,
      conflictHandler: createConflictHandler<RxRecurringNaked>(),
    },
    recurring_occurrence: {
      schema: RECURRING_OCCURRENCE_SCHEMA,
      // conflictHandler: createConflictHandler<RxRecurringOccurrenceNaked>(),
      // TODO find out why key field is not cast correctly
      conflictHandler: createConflictHandler<any>(),
    },
  });
  const collections = [db.task, db.recurring, db.recurring_occurrence];

  /**
   * To make it possible to detect and resolve conflicts,
   * we use a custom field 'replication_revision' that
   * works similar to the rxdb revision and will be automatically updated on each write.
   * @link https://rxdb.info/transactions-conflicts-revisions.html
   */

  collections.forEach((collection) => {
    // TODO check
    collection.preInsert((docData) => {
      console.log("PRE_INSERT");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      docData.replication_revision = createRevision(
        db.token,
        // (input) => 'INITIAL_' + db.hashFunction(JSON.stringify(docData)),
        // docData as RxDocumentData<RxTaskDocument>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        docData
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return docData;
    }, false);

    // TODO check
    collection.preRemove((docData) => {
      console.log(" PRE REMOVE !!");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      const oldRevHeight = parseRevision(docData.replication_revision).height;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      docData.replication_revision = `${oldRevHeight + 1}-${db.hashFunction(
        JSON.stringify(docData)
      )}`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return docData;
    }, false);

    // TODO check
    collection.preSave((docData) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      const oldRevHeight = parseRevision(docData.replication_revision).height;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      docData.replication_revision = `${oldRevHeight + 1}-${db.hashFunction(
        JSON.stringify(docData)
      )}`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return docData;
    }, false);
  });

  return db;
}

function getStorageKey(): string {
  const urlString = window.location.href;
  const url = new URL(urlString);
  let storageKey = url.searchParams.get("storage");
  if (!storageKey) {
    storageKey = "dexie";
  }
  return storageKey;
}

/**
 * Easy toggle of the storage engine via query parameter.
 */
function getStorage(): RxStorage<any, any> {
  const storageKey = getStorageKey();
  console.log("DB: storageKey: " + storageKey);

  if (storageKey === "dexie") {
    return getRxStorageDexie();
  } else {
    throw new Error("storage key not defined " + storageKey);
  }
}

/**
 * In the e2e-test we get the database-name from the get-parameter
 * In normal mode, the database name is 'tasksdb'
 */
function getDatabaseName() {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const dbNameFromUrl = url.searchParams.get("database");

  let ret = DB_PREFIX;
  if (dbNameFromUrl) {
    console.log("databaseName from url: " + dbNameFromUrl);
    ret += dbNameFromUrl;
  }
  return ret;
}
