import styles from "./GridBtn.module.scss";
import { Ico } from "./Ico";
import classNames from "classnames";

export const GridBtn = (props: {
  title: string;
  onClick?: () => void;
  ico?: string;
  isHorizontal?: boolean;
}) => {
  return (
    <button
      onClick={props.onClick}
      className={classNames([
        styles.GridBtn,
        { [styles.isHorizontal]: props.isHorizontal },
      ])}
      type="button"
    >
      {props.ico && <Ico className={styles.btnIco} name={props.ico} />}
      <span className={styles.btnText}>{props.title}</span>
    </button>
  );
};
