import React from "react";
import { getWeekDayName } from "../../../util/getWeekDayName";
import styles from "./SelectDayBar.module.scss";
import { isToday } from "../../../util/isToday";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../shared/hooks";
import { selectTasks, type Task } from "../../../store/task/taskSlice";
import { Ico } from "../../../ui-components/Ico";
import { getMaterialIdFromSymbolId } from "../../../util/getMaterialIdFromSymbolId";
import { DEFAULT_TASK_MATERIAL_ICON } from "../../../shared/app.const";
import { getIsoStrDateOnly } from "../../../util/getIsoStrDateOnly";
import { selectRecurringCfgs } from "../../../store/recurring/recurringSlice";
import { getRecurringForDayWithoutDetached } from "../../../util/getRecurringForDayWithoutDetached";
import { selectRecurringOccurrences } from "../../../store/recurringOccurrence/recurringOccurrenceSlice";
import { isUniqueById } from "../../../util/isUniqueById";
import { getColor } from "../../../util/getColor";
import { type RxRecurringNaked } from "../../../rxdb/recurring.schema";
import {
  selectIsShowSidebar,
  toggleIsShowSidebar,
} from "../../../store/ui/uiSlice";

interface Day {
  title: string;
  date: Date;
  isSelected: boolean;
  isToday: boolean;
  dayNr: number;
  tasksForDay: (Task | RxRecurringNaked)[];
}

export const SelectDayBar = (props: {
  selectedDay: Date;
  onDaySelect: (day: Date) => void;
}) => {
  const dispatch = useAppDispatch();
  const locales = "en-US";
  const tasks = useAppSelector(selectTasks);
  const recurringCfgs = useAppSelector(selectRecurringCfgs);
  const isShowSidebar = useAppSelector(selectIsShowSidebar);
  const recurringOccurrences = useAppSelector(selectRecurringOccurrences);
  const daysInWeek: Day[] = Array.from(Array(7).keys()).map((i) => {
    const day = new Date(props.selectedDay);
    day.setDate(day.getDate() - day.getDay() + i);
    const dayStr = getIsoStrDateOnly(day);
    const tasksForDay = tasks.filter((t) => t.day === dayStr);
    const recurringForDay = getRecurringForDayWithoutDetached(
      recurringCfgs,
      recurringOccurrences,
      dayStr
    );
    const allTasksForDay = [...tasksForDay, ...recurringForDay];

    // TODO make it more efficient
    allTasksForDay.length =
      allTasksForDay.length >= 4 ? 4 : allTasksForDay.length;

    if (!isUniqueById(allTasksForDay)) {
      console.log({ allTasksForDay });
      throw new Error("Duplicate task ids for day " + dayStr);
    }

    return {
      date: day,
      dayNr: day.getDate(),
      title: getWeekDayName(day),
      isToday: isToday(day),
      isSelected: day.getDate() === props.selectedDay.getDate(),
      tasksForDay: allTasksForDay,
    };
  });

  const goPrevWeek = () => {
    const newDate = new Date(props.selectedDay);
    newDate.setDate(newDate.getDate() - 7);
    props.onDaySelect(newDate);
  };

  const goNextWeek = () => {
    const newDate = new Date(props.selectedDay);
    newDate.setDate(newDate.getDate() + 7);
    props.onDaySelect(newDate);
  };

  // TODO display month name instead of week nr
  return (
    <div className={styles.SelectDayBar}>
      <div className={styles.weekSelector}>
        {!isShowSidebar && (
          <button
            className="btn-ico"
            onClick={() => {
              dispatch(toggleIsShowSidebar());
            }}
          >
            <Ico name="book" />
          </button>
        )}

        <div className={styles.prevNextBtn} onClick={() => goPrevWeek()}>
          <Ico name="chevron_left" />
        </div>
        <div
          className={styles.monthName}
          onClick={() => props.onDaySelect(new Date())}
        >
          {props.selectedDay.toLocaleDateString(locales, { month: "long" })}
          &nbsp;
          <em>
            {props.selectedDay.toLocaleDateString(locales, { year: "numeric" })}
          </em>
        </div>
        <div className={styles.prevNextBtn} onClick={() => goNextWeek()}>
          <Ico name="chevron_right" />
        </div>
      </div>

      <div className={styles.days}>
        {daysInWeek.map((day, index) => {
          return (
            <div
              title={day.date.toDateString()}
              className={classNames(styles.day, {
                [styles.isSelected]: day.isSelected,
                [styles.isToday]: day.isToday,
              })}
              onClick={() => props.onDaySelect(day.date)}
              key={index}
            >
              <div className={styles.dayName}>{day.title}</div>
              <div className={styles.dayNr}>{day.dayNr}</div>
              <div className={styles.dayTaskPreview}>
                {day.tasksForDay.map((t) => (
                  <div
                    style={
                      {
                        "--task-color": getColor(t.color),
                      } as any
                    }
                    key={t.id}
                    className={styles.dayTaskPreviewIcoWrapper}
                  >
                    <Ico
                      name={
                        getMaterialIdFromSymbolId(t.symbol) ??
                        DEFAULT_TASK_MATERIAL_ICON
                      }
                      className="izXs iContrast"
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
