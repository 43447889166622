// TODO unit test
export const strTimeToDbFloat = (timeStr: string) => {
  const split = timeStr.split(":");

  if (split.length !== 2) {
    throw new Error("Invalid Timestr");
  }
  const hours = +split[0];
  const minutes = Math.round((+split[1] / 6) * 10) / 100;

  return hours + minutes;
};
