import React, { type FormEvent, useEffect, useState } from "react";
import { supabase } from "../../api/supabaseClient";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import Modal from "react-modal";
import styles from "./Account.module.scss";
import classNames from "classnames";
import { Ico } from "../../ui-components/Ico";
import { getDB } from "../../rxdb/db";

const Account = ({ session }: any) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const getProfile = async () => {
      try {
        setLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { user } = session;

        const { data, error, status } = await supabase
          .from("profiles")
          .select()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          .eq("id", user.id)
          .single();

        if (error && status !== 406) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          throw new Error(error as any);
        }

        console.log(data);
      } catch (error: any) {
        console.error(error);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        alert(error.message);
      } finally {
        setLoading(false);
      }
    };

    void getProfile();
  }, [session]);

  const updateProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { user } = session;

      const updates = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        id: user.id,
        updated_at: getDbStrDateFull(),
      };

      const { error } = await supabase.from("profiles").upsert(updates);

      if (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(error as any);
      }
    } catch (error: any) {
      console.error(error);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className={classNames([styles.accountBtn, "btn", "btn-ico"])}
        onClick={() => setIsModalOpen(true)}
      >
        <Ico name="person" />
      </button>
      <Modal
        isOpen={isModalOpen}
        className="A"
        onRequestClose={() => setIsModalOpen(false)}
      >
        <header>Profile</header>
        <div onClick={() => setIsModalOpen(false)} className="modalCloseBtn">
          <Ico name="close" />
        </div>

        <div aria-live="polite">
          {loading ? (
            "Saving ..."
          ) : (
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <form onSubmit={updateProfile} className="mt2">
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
              <div title={session.user.id}>Email: {session.user.email}</div>

              <div className="mt2">
                <button className="btn" disabled={loading}>
                  Update profile
                </button>
              </div>
            </form>
          )}
          <button
            type="button"
            className="btn mt2"
            onClick={() => {
              void supabase.auth.signOut();
              void getDB().then(async (db) => {
                console.log("Delete all user data (rxdb)", await db.remove());
              });
            }}
          >
            Sign Out
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Account;
