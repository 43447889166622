import { useAppDispatch } from "../hooks";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { type RxRecurringOccurrenceNaked } from "../../rxdb/recurringOccurrence.schema";
import { type Task, updateTaskRxDb } from "../../store/task/taskSlice";
import { type RxTaskNaked } from "../../rxdb/task.schema";
import { isRecurringTask } from "../../util/isRecurringTask";
import {
  addRecurringOccurrenceRxDb,
  updateRecurringOccurrenceRxDb,
} from "../../store/recurringOccurrence/recurringOccurrenceSlice";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import { TMP_REV } from "../../rxdb/commonDB.const";
import { type AsyncThunkAction } from "@reduxjs/toolkit";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";

const getCompletedAtUpdateAction = (
  taskOrRecurring: RxTaskNaked | RxRecurringNaked,
  recurringOccurrence?: RxRecurringOccurrenceNaked,
  taskDay?: Date
): AsyncThunkAction<unknown, unknown, any> => {
  const isRecurring = recurringOccurrence ?? isRecurringTask(taskOrRecurring);

  if (isRecurring) {
    if (recurringOccurrence?.id) {
      return updateRecurringOccurrenceRxDb({
        id: recurringOccurrence.id,
        changes: {
          completed_at: !recurringOccurrence.completed_at
            ? getDbStrDateFull()
            : null,
        },
      });
    } else {
      if (!taskDay) {
        throw new Error(
          "initialStartDayIfTask is required for recurring occurrence"
        );
      }

      return addRecurringOccurrenceRxDb({
        completed_at: getDbStrDateFull(),
        recurring: taskOrRecurring.id,
        user_id: taskOrRecurring.user_id,
        order_index: null,
        id: uuid(),
        detached_task: null,
        created_at: getDbStrDateFull(),
        modified_at: getDbStrDateFull(),
        day: getIsoStrDateOnly(taskDay),
        is_detached: false,
        replication_revision: TMP_REV,
        _deleted: false,
        subtask_occurrences: [],
      });
    }
  } else {
    return updateTaskRxDb({
      id: taskOrRecurring.id,
      changes: {
        completed_at: !(taskOrRecurring as RxTaskNaked).completed_at
          ? getDbStrDateFull()
          : null,
      },
    });
  }
};

export const useToggleCompletedAt = (
  taskDay: Date,
  taskOrRecurring: Task | RxRecurringNaked,
  currentDayRecurringOccurrence?: RxRecurringOccurrenceNaked
) => {
  const dispatch = useAppDispatch();

  const toggleCompletedAt = () => {
    void dispatch(
      getCompletedAtUpdateAction(
        taskOrRecurring,
        currentDayRecurringOccurrence,
        taskDay
      )
    );
  };

  return {
    toggleCompletedAt,
  };
};
