import { type TimelineGapMsgEntry } from "../timeline.model";
import styles from "./TimelineGapMsg.module.scss";
import { humanizeDuration } from "../../../../util/humanizeDuration";

export const TimelineGapMsg = (props: { entry: TimelineGapMsgEntry }) => {
  const entry = props.entry;
  return (
    <div className={styles.TimelineGapMsg}>
      {entry.isInThePast ? (
        <div>
          Took a <em>{humanizeDuration(entry.durationInMin)}</em> break.
        </div>
      ) : (
        <div>
          Need a break of <em>{humanizeDuration(entry.durationInMin)}</em>?
        </div>
      )}
    </div>
  );
};
