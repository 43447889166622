import {
  type TimelineEntry,
  TimelineEntryType,
} from "../features/DayView/Timeline/timeline.model";
import { getIsoStrTime } from "./getIsoStrTime";
import { getDiffInDays } from "./getDiffInDays";

export const getFirstFreePeriodStart = (
  timelineEntries: TimelineEntry[],
  periodDurationInMin: number,
  targetDay: Date,
  now: Date = new Date()
): string => {
  const isTargetDayToday = getDiffInDays(targetDay, now) === 0;
  if (timelineEntries.length === 0) {
    if (isTargetDayToday) {
      const nd = new Date(now);
      nd.setHours(nd.getHours() + 1, 0, 0, 0);
      return getIsoStrTime(nd);
    } else {
      return "9:00";
    }
  }
  const firstGapItem = timelineEntries.find(
    (entry) =>
      (entry.type === TimelineEntryType.GAP_MSG ||
        entry.type === TimelineEntryType.ADD_TASK_PROMPT) &&
      entry.durationInMin >= periodDurationInMin &&
      (!isTargetDayToday ||
        entry.start >= now ||
        (entry.end.getTime() - now.getTime()) / 60000 > periodDurationInMin)
  );

  if (firstGapItem) {
    if (firstGapItem.start < now) {
      return getIsoStrTime(now);
    }
    return getIsoStrTime(firstGapItem.start);
  }

  return getIsoStrTime(timelineEntries[timelineEntries.length - 1].end);
};
