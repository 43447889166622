import React from "react";
import styles from "./AddSubtaskInput.module.scss";
import { getDbStrDateFull } from "../../../util/getDbStrDateFull";
import { type TmpSubtask } from "../taskEdit.model";
import { Ico } from "../../../ui-components/Ico";

export function AddSubtaskInput(props: {
  isAutoFocus?: boolean;
  tmpSubtask: TmpSubtask;
  onDelete: () => void;
  onUpdate: (upd: Partial<TmpSubtask>) => void;
}) {
  return (
    <fieldset className={styles.AddSubtaskInput}>
      <input
        type="checkbox"
        checked={!!props.tmpSubtask.completed_at}
        onChange={(event) => {
          props.onUpdate({
            completed_at: !props.tmpSubtask.completed_at
              ? getDbStrDateFull()
              : null,
          });
        }}
      />
      <input
        type="text"
        autoFocus={props.isAutoFocus}
        minLength={3}
        placeholder=""
        value={props.tmpSubtask.title}
        onChange={(ev) => {
          props.onUpdate({ title: ev.target.value });
        }}
        name="taskTitle"
      />
      <button
        type="button"
        className="btn btn-ico"
        onClick={() => {
          props.onDelete();
        }}
      >
        <Ico name="close" />
      </button>
    </fieldset>
  );
}
