import styles from "./TaskEditForm.module.scss";
import React, { useEffect, useState } from "react";
import { AddSubtaskInput } from "./AddSubtaskInput/AddSubtaskInput";
import { useSelector } from "react-redux";
import { selectSelectedEpochDayAsDate } from "../../store/ui/uiSlice";
import { getIsoStrTime } from "../../util/getIsoStrTime";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import {
  type RecurringOwnFields,
  type TmpSubtask,
  type TmpTask,
} from "./taskEdit.model";
import { strTimeToDbFloat } from "../../util/strTimeToDbFloat";
import { getNewTmpSubtask } from "./getNewTmpSubtask";
import classNames from "classnames";
import { Ico } from "../../ui-components/Ico";
import { getSymbolIdFromTitle } from "./getSymbolIdFromTitle";
import { getMaterialIdFromSymbolId } from "../../util/getMaterialIdFromSymbolId";
import {
  DEFAULT_TASK_DURATION,
  DEFAULT_TASK_MATERIAL_ICON,
  DEFAULT_TASK_SYMBOL_ID,
} from "../../shared/app.const";
import { dbStartTimeAndDateStrToDate } from "../../util/dbStartTimeAndDateStrToDate";
import { EditRecurring } from "./EditRecurring/EditRecurring";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { getColor } from "../../util/getColor";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import { useFirstFreePeriodStartForDay } from "../../store/task/taskSlice";

export const TaskEditForm = (props: {
  isEdit?: boolean;
  tmpTask: TmpTask;
  tmpSubtasks: TmpSubtask[];
  recurringCfg: RxRecurringNaked | RecurringOwnFields | null;
  onSubmit: (tmpTask: TmpTask) => void;
  onUpdateTmpTask: (updTmpTask: TmpTask) => void;
  onUpdateTmpSubtasks: (updTmpSubtasks: TmpSubtask[]) => void;
  onUpdateRecurringCfg: (updRecurring: RecurringOwnFields | null) => void;
}) => {
  const tmpTask = props.tmpTask;
  const tmpSubtasks = props.tmpSubtasks;
  const isAutoSymbolMode = !props.isEdit;

  const selectedDay = useSelector(selectSelectedEpochDayAsDate);
  const initialDefaultTime = useFirstFreePeriodStartForDay(
    selectedDay,
    new Date(),
    tmpTask.duration ?? DEFAULT_TASK_DURATION
  );

  const initialDate =
    tmpTask.day && tmpTask.start_time
      ? dbStartTimeAndDateStrToDate(tmpTask.start_time, tmpTask.day)
      : dbStartTimeAndDateStrToDate(
          strTimeToDbFloat(initialDefaultTime),
          getDbStrDateFull(selectedDay)
        );

  const [isAutofocusSubtasks, setIsAutofocusSubtasks] =
    useState<boolean>(false);
  const [tmpStartTime, setTmpStartTime] = useState<string>(
    getIsoStrTime(initialDate)
  );
  const [tmpStartDate, setTmpStartDate] = useState<string>(
    getIsoStrDateOnly(initialDate)
  );

  useEffect(() => {
    // needs to be called otherwise values won't be updated there
    updateTmpTask({});
  }, [tmpStartDate, tmpStartTime]);

  const updateTmpTaskTitle = (newTitle: string) => {
    if (isAutoSymbolMode) {
      const symbol = getSymbolIdFromTitle(newTitle);
      updateTmpTask({
        title: newTitle,
        symbol: symbol ?? DEFAULT_TASK_SYMBOL_ID,
      });
    } else {
      updateTmpTask({ title: newTitle });
    }
  };

  const updateTmpTask = (updateProps: Partial<TmpTask>) => {
    props.onUpdateTmpTask({
      ...props.tmpTask,
      ...updateProps,
      // NOTE: we filter out invalid values, that might come from an invalid input value
      // TODO check date stuff
      ...(tmpStartDate
        ? { day: getIsoStrDateOnly(new Date(tmpStartDate)) }
        : {}),
      ...(tmpStartTime ? { start_time: strTimeToDbFloat(tmpStartTime) } : {}),
    });
  };

  const updateTmpSubtask = (
    updateIndex: number,
    updateProps: Partial<TmpSubtask>
  ) => {
    const updatedItems = tmpSubtasks.map((tmpSubtask, i) =>
      updateIndex === i ? { ...tmpSubtask, ...updateProps } : tmpSubtask
    );

    props.onUpdateTmpSubtasks(updatedItems);
  };

  const updateIsInInbox = (isInInbox: boolean) => {
    if (isInInbox) {
      props.onUpdateRecurringCfg(null);
      updateTmpTask({ is_in_inbox: true, day: null, start_time: null });
    } else {
      updateTmpTask({ is_in_inbox: false });
    }
  };

  const removeTmpSubtask = (indexToRemove: number) => {
    // TODO check
    props.onUpdateTmpSubtasks(
      tmpSubtasks.filter((st, index) => index !== indexToRemove)
    );
  };

  return (
    <form
      className={styles.TaskEditForm}
      onSubmit={(ev) => {
        console.log(tmpStartDate);
        console.log(tmpStartTime);
        ev.preventDefault();
        props.onSubmit(props.tmpTask);
      }}
      style={
        {
          "--primary-color": getColor(tmpTask.color),
          "--btn-bg-color": getColor(tmpTask.color),
        } as any
      }
    >
      <div className={styles.taskTitleBox}>
        <div className={styles.taskIcon}>
          <Ico
            name={
              getMaterialIdFromSymbolId(tmpTask.symbol) ??
              DEFAULT_TASK_MATERIAL_ICON
            }
          />
        </div>

        <input
          className={styles.taskTitleInput}
          type="text"
          autoFocus
          placeholder="Please enter a task title"
          minLength={1}
          value={tmpTask.title}
          onChange={(ev) => updateTmpTaskTitle(ev.target.value)}
          name="taskTitle"
        />
      </div>

      <label>
        <input
          type="checkbox"
          checked={tmpTask.is_in_inbox}
          onChange={(ev) => updateIsInInbox(!tmpTask.is_in_inbox)}
        />{" "}
        Is an inbox task
      </label>

      {!tmpTask.is_in_inbox && (
        <section className={styles.dateTimeSection}>
          <h4 className={styles.sectionLabel}>When?</h4>

          <label>
            <input
              type="checkbox"
              checked={tmpTask.is_all_day}
              onChange={(ev) =>
                updateTmpTask({ is_all_day: !tmpTask.is_all_day })
              }
            />{" "}
            Is an all day task
          </label>

          {!tmpTask.is_all_day && (
            <input
              type="time"
              value={tmpStartTime}
              onChange={(ev) => setTmpStartTime(ev.target.value)}
            />
          )}
          <input
            type="date"
            value={tmpStartDate}
            onChange={(ev) => setTmpStartDate(ev.target.value)}
          />
        </section>
      )}
      <section className={styles.durationSection}>
        <h4 className={styles.sectionLabel}>How long (in minutes)?</h4>
        <input
          type="number"
          value={tmpTask.duration ?? 15}
          placeholder="in minutes"
          min="1"
          max="999999"
          onChange={(ev) => updateTmpTask({ duration: +ev.target.value })}
        />
      </section>

      {!tmpTask.is_in_inbox && (
        <section className={styles.recurringSection}>
          <h4 className={styles.sectionLabel}>How often?</h4>
          <EditRecurring
            initialStartDayIfTask={new Date(tmpTask.day as string)}
            recurringCfg={props.recurringCfg}
            onUpdate={(upd) => {
              console.log("update Recurring", upd);
              props.onUpdateRecurringCfg(upd);
            }}
          />
        </section>
      )}

      <section className={styles.colorSection}>
        <h4 className={styles.sectionLabel}>What color?</h4>
        <input
          value={tmpTask.color}
          type="color"
          onChange={(ev) => updateTmpTask({ color: ev.target.value })}
        />
      </section>

      {/* <section className={styles.repeatSection}>REPEAT_INPUT</section> */}
      {/* <section className={styles.alertSection}>ALERT_INPUT</section> */}

      <section className={styles.subtaskSection}>
        <h4 className={styles.sectionLabel}>Subtasks</h4>

        {tmpSubtasks.map((tmpSubtask, i) => (
          <AddSubtaskInput
            tmpSubtask={tmpSubtask}
            isAutoFocus={isAutofocusSubtasks}
            onUpdate={(upd) => updateTmpSubtask(i, upd)}
            onDelete={() => removeTmpSubtask(i)}
            key={i}
          />
        ))}
        <button
          className={classNames(["btn w100", styles.addSubtaskBtn])}
          type="button"
          onClick={() => {
            props.onUpdateTmpSubtasks([...tmpSubtasks, getNewTmpSubtask()]);
            setIsAutofocusSubtasks(true);
          }}
        >
          + Add subtask
        </button>

        <h4 className={styles.sectionLabel}>Notes</h4>
        <textarea
          rows={4}
          value={tmpTask.note}
          onChange={(ev) => updateTmpTask({ note: ev.target.value })}
        />
      </section>

      <button
        className={classNames("btn", "btn-large", styles.submitBtn)}
        type="submit"
      >
        {props.isEdit ? "Update" : "Create"}
      </button>
    </form>
  );
};
