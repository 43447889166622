export const getIsoStrDateOnly = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const monthStr = `${month < 10 ? "0" : ""}${month}`;
  const dayStr = `${day < 10 ? "0" : ""}${day}`;

  return `${year}-${monthStr}-${dayStr}`;
};
