import styles from "./TaskCheckbox.module.scss";
import { Ico } from "../../../../../ui-components/Ico";

export const TaskCheckbox = (props: {
  checked: boolean;
  onChange: (isChecked: boolean) => void;
}) => {
  return (
    <div
      className={
        styles.TaskCheckbox + " " + (props.checked ? styles.isChecked : "")
      }
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        props.onChange(!props.checked);
      }}
    >
      {props.checked ? <Ico name="done" className="izl iContrast" /> : ""}
    </div>
  );
};
