import { useAppDispatch } from "../../shared/hooks";
import { isRecurringTask } from "../../util/isRecurringTask";
import {
  removeRecurringRxDb,
  useRecurringById,
} from "../../store/recurring/recurringSlice";
import {
  removeTaskRxDb,
  type Task,
  updateTaskRxDb,
  useTaskById,
} from "../../store/task/taskSlice";
import { type RxRecurringOccurrenceNaked } from "../../rxdb/recurringOccurrence.schema";
import {
  addRecurringOccurrenceRxDb,
  removeRecurringOccurrencesRxDb,
  updateRecurringOccurrenceRxDb,
  useRecurringOccurrenceForDayByRecurringId,
  useRecurringOccurrencesByRecurringId,
} from "../../store/recurringOccurrence/recurringOccurrenceSlice";
import { type TmpSubtask } from "../TaskEditForm/taskEdit.model";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { TMP_REV } from "../../rxdb/commonDB.const";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import { getDeleteFutureRecurringActions } from "../RecurringDeletePromptModal/getDeleteFutureRecurringActions";
import { useToggleCompletedAt } from "../../shared/taskRecurringWrapper/useToggleCompletedAt";
import { type RxTaskNaked } from "../../rxdb/task.schema";

export const useTaskContextModal = (
  taskDay: Date,
  taskOrRecurringI: Task | RxRecurringNaked,
  onClose: () => void
) => {
  const dispatch = useAppDispatch();

  const isRecurring = isRecurringTask(taskOrRecurringI);
  // selector stuff needed for dynamic updates
  const taskOrRecurringLive = isRecurring
    ? useRecurringById(taskOrRecurringI.id)
    : useTaskById(taskOrRecurringI.id);

  const currentDayRecurringOccurrence: undefined | RxRecurringOccurrenceNaked =
    isRecurring
      ? useRecurringOccurrenceForDayByRecurringId(
          taskDay,
          taskOrRecurringLive.id
        ) ?? undefined
      : undefined;

  const { toggleCompletedAt } = useToggleCompletedAt(
    taskDay,
    taskOrRecurringLive,
    currentDayRecurringOccurrence
  );

  const completedAt = currentDayRecurringOccurrence
    ? currentDayRecurringOccurrence.completed_at
    : (taskOrRecurringLive as RxTaskNaked).completed_at ?? null;

  const allRecurringOccurrencesForCurrent =
    useRecurringOccurrencesByRecurringId(taskOrRecurringLive.id);
  console.log({ allRecurringOccurrencesForCurrent });

  const subtasks: TmpSubtask[] =
    currentDayRecurringOccurrence?.subtask_occurrences
      ? currentDayRecurringOccurrence.subtask_occurrences.map((sto) => {
          const originalSubtask = taskOrRecurringLive.subtasks.find(
            (st) => st.id === sto.subtask
          );
          if (!originalSubtask) {
            console.error("Unable to find original subtask for instance");
            return {
              id: uuid(),
              title: "unable to find original subtask for instance ;(",
              completed_at: null,
            };
          }

          return {
            id: originalSubtask.id as string,
            completed_at: sto.completed_at ?? null,
            title: originalSubtask.title as string,
          };
        })
      : (taskOrRecurringLive.subtasks as TmpSubtask[]);

  const updateSubtasks = (targetId: string, changes: Partial<TmpSubtask>) => {
    const updatedSubtasks = subtasks.map((sti, index) => {
      return sti.id === targetId
        ? {
            ...sti,
            ...changes,
          }
        : sti;
    });

    if (isRecurring) {
      // TODO implement recurring_occurrence creation/or update
      console.log("NOT IMPLEMENTED FOR RECURRING");
      const subtaskOccurrences = updatedSubtasks.map((tmpSubtask) => ({
        id: uuid(),
        subtask: tmpSubtask.id,
        completed_at: tmpSubtask.completed_at,
      }));
      if (currentDayRecurringOccurrence) {
        void dispatch(
          updateRecurringOccurrenceRxDb({
            id: currentDayRecurringOccurrence.id,
            changes: { subtask_occurrences: subtaskOccurrences },
          })
        );
      } else {
        void dispatch(
          addRecurringOccurrenceRxDb({
            id: uuid(),
            user_id: taskOrRecurringLive.user_id,
            created_at: getDbStrDateFull(),
            modified_at: getDbStrDateFull(),
            recurring: taskOrRecurringLive.id,
            day: getIsoStrDateOnly(taskDay),
            replication_revision: TMP_REV,
            order_index: null,
            completed_at: null,
            detached_task: null,
            is_detached: false,
            _deleted: false,
            subtask_occurrences: subtaskOccurrences,
          })
        );
      }
    } else {
      void dispatch(
        updateTaskRxDb({
          id: taskOrRecurringLive.id,
          changes: {
            subtasks: updatedSubtasks,
          },
        })
      );
    }
  };

  const deleteSingleRecurring = () => {
    if (currentDayRecurringOccurrence) {
      void dispatch(
        updateRecurringOccurrenceRxDb({
          id: currentDayRecurringOccurrence.id,
          changes: { is_detached: true },
        })
      );
    } else {
      void dispatch(
        addRecurringOccurrenceRxDb({
          id: uuid(),
          user_id: taskOrRecurringLive.user_id,
          created_at: getDbStrDateFull(),
          modified_at: getDbStrDateFull(),
          recurring: taskOrRecurringLive.id,
          day: getIsoStrDateOnly(taskDay),
          subtask_occurrences: [],
          replication_revision: TMP_REV,
          completed_at: null,
          order_index: null,
          detached_task: null,
          is_detached: true,
          _deleted: false,
        })
      );
    }
    onClose();
  };
  const deleteFutureRecurring = () => {
    getDeleteFutureRecurringActions(
      taskDay,
      taskOrRecurringLive as RxRecurringNaked,
      allRecurringOccurrencesForCurrent
    ).forEach((action) => {
      dispatch(action);
    });
    onClose();
  };

  const deleteAllRecurring = () => {
    void dispatch(removeRecurringRxDb(taskOrRecurringLive.id));
    if (allRecurringOccurrencesForCurrent.length > 0) {
      void dispatch(
        removeRecurringOccurrencesRxDb(
          allRecurringOccurrencesForCurrent.map((occurrence) => occurrence.id)
        )
      );
    }
    onClose();
  };

  const deleteTask = () => {
    if (confirm("Are you sure you want to delete the task?")) {
      // TODO handle recurring cases
      void dispatch(removeTaskRxDb(taskOrRecurringLive.id));
      onClose();
    }
  };

  return {
    isRecurring,
    taskOrRecurringLive,
    subtasks,
    completedAt,
    updateSubtasks,
    deleteAllRecurring,
    deleteSingleRecurring,
    deleteFutureRecurring,
    deleteTask,
    toggleCompletedAt,
  };
};
