import { type RecurringOwnFields, RecurringType } from "../taskEdit.model";
import { useState } from "react";
import styles from "./EditRecurring.module.scss";
import { type RxRecurringNaked } from "../../../rxdb/recurring.schema";
import { getIsoStrDateOnly } from "../../../util/getIsoStrDateOnly";

export const EditRecurring = (props: {
  initialStartDayIfTask: Date;
  recurringCfg: RxRecurringNaked | RecurringOwnFields | null;
  onUpdate: (update: RecurringOwnFields | null) => void;
}) => {
  const [recurringType, setRecurringType] = useState<RecurringType | null>(
    props.recurringCfg?.recurring_type ?? null
  );
  const startsDayAsDate = new Date(
    props.recurringCfg?.start_day ?? props.initialStartDayIfTask
  );
  const OPTIONS = [
    { label: "Once", val: null, repeatLabel: "NONE" },
    { label: "Daily", val: RecurringType.Daily, repeatLabel: "Day" },
    { label: "Weekly", val: RecurringType.Weekly, repeatLabel: "Week" },
    { label: "Monthly", val: RecurringType.Monthly, repeatLabel: "Month" },
  ];
  const WEEKDAYS: { label: string; key: keyof RxRecurringNaked }[] = [
    { label: "Mon", key: "monday" },
    { label: "Tue", key: "tuesday" },
    { label: "Wed", key: "wednesday" },
    { label: "Thu", key: "thursday" },
    { label: "Fri", key: "friday" },
    { label: "Sat", key: "saturday" },
    { label: "Sun", key: "sunday" },
  ];
  const updateType = (newRecurringType: RecurringType | null) => {
    setRecurringType(newRecurringType);
    if (newRecurringType === null) {
      props.onUpdate(null);
    } else {
      propagateUpdate({
        recurring_type: newRecurringType,
      });
    }
  };

  const toggleWeekDay = (weekDayId: keyof RxRecurringNaked) => {
    if (recurringType === RecurringType.Weekly) {
      propagateUpdate({
        [weekDayId]: props.recurringCfg ? !props.recurringCfg[weekDayId] : true,
      });
    }
  };

  const propagateUpdate = (upd: Partial<RecurringOwnFields>) => {
    if (!recurringType && !("recurring_type" in upd)) {
      throw new Error("No recurring type selected");
    }

    props.onUpdate({
      recurring_type: recurringType ?? (upd.recurring_type as RecurringType),
      interval: 1,
      start_day: getIsoStrDateOnly(startsDayAsDate),
      end_day: null,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      ...props.recurringCfg,
      ...upd,
    });
  };

  const repeatLabel: string =
    OPTIONS.find((opt) => opt.val === recurringType)?.repeatLabel ?? "NONE";

  return (
    <div className={styles.EditRecurring}>
      <div className={styles.btnWrapper}>
        {OPTIONS.map((opt) => (
          <button
            type="button"
            key={opt.val}
            className={recurringType === opt.val ? "btn" : "btn-outline"}
            onClick={() => updateType(opt.val)}
          >
            {opt.label}
          </button>
        ))}
      </div>
      {recurringType !== null && (
        <>
          <div className={styles.startEndLabel}>
            Starts at {startsDayAsDate.toLocaleDateString()}
          </div>

          {/* TODO move to context modal component */}
          <div>
            <label htmlFor="start-day">Start day</label>
            <input
              type="date"
              id="start-day"
              placeholder="Start day"
              required
              value={
                props.recurringCfg?.start_day ??
                getIsoStrDateOnly(startsDayAsDate)
              }
              onChange={(e) => {
                propagateUpdate({
                  start_day:
                    e.target.value ?? getIsoStrDateOnly(startsDayAsDate),
                });
              }}
            />
          </div>

          <div>
            <label htmlFor="end-day">End day</label>
            <input
              type="date"
              id="end-day"
              placeholder="End day"
              value={props.recurringCfg?.end_day ?? undefined}
              onChange={(e) => {
                propagateUpdate({
                  end_day: e.target.value ?? null,
                });
              }}
            />
          </div>

          <div className={styles.repeatWrapper}>
            <input
              type="number"
              id="interval"
              placeholder={`Repeat every X ${repeatLabel} `}
              min="1"
              max="999999"
              value={props.recurringCfg?.interval ?? 1}
              onChange={(e) => {
                propagateUpdate({ interval: parseInt(e.target.value, 10) });
              }}
            />
            <label htmlFor="interval">{`Repeat every ${
              props.recurringCfg?.interval ?? 1
            } ${repeatLabel} `}</label>
          </div>
        </>
      )}
      {recurringType === RecurringType.Weekly && (
        <div className={styles.weekDaySelectWrapper}>
          {WEEKDAYS.map((weekDay) => (
            <button
              type="button"
              key={weekDay.label}
              className={
                props.recurringCfg?.[weekDay.key] ? "btn" : "btn-outline"
              }
              onClick={() => {
                toggleWeekDay(weekDay.key);
              }}
            >
              {weekDay.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
