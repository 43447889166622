import {
  type ExtractDocumentTypeFromTypedRxJsonSchema,
  type RxJsonSchema,
  toTypedRxJsonSchema,
} from "rxdb";
import { DATE_STR_LENGTH } from "./commonDB.const";

export const TASK_SCHEMA_LITERAL = {
  title: "taskOrRecurring schema",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    // STANDARD REPLICATION FIELDS
    id: {
      type: "string",
      minLength: 8,
      maxLength: 100,
    },
    replication_revision: {
      type: "string",
      minLength: 8,
    },
    received_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    // OTHER STANDARD FIELDS
    user_id: {
      type: "string",
    },
    created_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    modified_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    _deleted: {
      type: "boolean",
    },

    // OTHER FIELDS
    title: {
      type: ["string"],
    },
    symbol: {
      type: "string",
    },
    is_symbol_set: {
      type: "boolean",
    },
    color: {
      type: "string",
    },
    day: {
      type: ["string", "null"],
    },
    start_time: {
      type: ["number", "null"],
    },
    timezone: {
      type: ["string", "null"],
    },
    duration: {
      type: ["integer", "null"],
    },
    completed_at: {
      type: ["string", "null"],
    },
    is_all_day: {
      type: "boolean",
    },
    is_in_inbox: {
      type: "boolean",
    },
    note: {
      type: "string",
    },
    order_index: {
      type: ["integer", "null"],
    },
    calendar_day_index: {
      type: ["integer", "null"],
    },
    type: {
      type: ["integer"],
    },
    is_reminder_detached: {
      type: "boolean",
    },
    is_hidden: {
      type: "boolean",
    },
    subtasks: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          title: {
            type: "string",
          },
          completed_at: {
            type: ["string", "null"],
          },
        },
      },
    },
    alerts: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          type: {
            type: "string",
          },
          time: {
            type: "number",
          },
          offset: {
            type: ["integer", "null"],
          },
        },
      },
    },
    events: {
      type: "array",
      items: {
        type: "object",
        properties: {
          unknownyetall: {
            type: "string",
          },
        },
      },
    },
  },
  required: [
    "id",
    "title",
    "type",
    "user_id",
    "replication_revision",
    "created_at",
    "modified_at",
    "symbol",
    "is_symbol_set",
    "color",
    "duration",
    "is_all_day",
    "is_in_inbox",
    "note",
    "order_index",
    "is_reminder_detached",
    "is_hidden",
    // NOTE: we make all fields required, to make our life easier with the server communication
    // they are usually not required, but get a default value on the server so read model is practically
    // different from write model, but this gets confusing
    // NOTE2: received_at is the only exception, since we should avoid ever setting this on the client
    // "received_at",
    "calendar_day_index",
    "_deleted",
    "completed_at",
    "day",
    "start_time",
    "timezone",
    "subtasks",
    "events",
    "alerts",
  ],
  indexes: [],
} as const;

const SCHEMA_TYPED = toTypedRxJsonSchema(TASK_SCHEMA_LITERAL);
export type RxTaskNaked = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof SCHEMA_TYPED
>;
// TODO as any stuff required until merged: https://github.com/pubkey/rxdb/pull/4579
export const TASK_SCHEMA: RxJsonSchema<RxTaskNaked> =
  TASK_SCHEMA_LITERAL as any as RxJsonSchema<RxTaskNaked>;
