// NOTE: start time comes as 8.5 for 8:30 AM

export const dbStartTimeAndEpocheDaysToDate = (
  startTime: number,
  epochDays: number
): Date => {
  const dayDate = linuxEpochDaysToDate(epochDays);
  const hours = Math.floor(startTime);
  const minutes = Math.round((startTime - hours) * 60);
  const d = new Date(dayDate);
  d.setHours(hours);
  // NOTE: we're throwing away seconds and milliseconds
  d.setMinutes(minutes, 0, 0);
  return d;
};

const epoch = new Date(0);

const linuxEpochDaysToDate = (epochDays: number): Date => {
  return new Date(epoch.getTime() + epochDays * 24 * 60 * 60 * 1000);
};
