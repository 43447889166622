export const DATE_STR_LENGTH = 24;

export const TMP_REV = "___TMP_REV_LOCAL_ONLY___";

// unfortunately typing is not smart enough to catch importing but we keep it here
// to allow for copy&pasta at least
export const STANDARD_REPLICATION_SCHEMA_FIELDS = {
  // STANDARD REPLICATION FIELDS
  id: {
    type: "string",
    minLength: 8,
    maxLength: 100,
  },
  replication_revision: {
    type: "string",
    minLength: 8,
  },
  received_at: {
    // datetime string
    type: "string",
    minLength: DATE_STR_LENGTH,
  },
  // OTHER STANDARD FIELDS
  user_id: {
    type: "string",
  },
  created_at: {
    // datetime string
    type: "string",
    minLength: DATE_STR_LENGTH,
  },
  modified_at: {
    // datetime string
    type: "string",
    minLength: DATE_STR_LENGTH,
  },
};
