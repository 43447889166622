import { AddTaskModal } from "../AddTaskModal/AddTaskModal";
import {
  selectContextModalTaskOrRecurring,
  selectIsShowAddTaskModal,
  setContextModalTask,
  setIsShowAddTaskModal,
} from "../../store/ui/uiSlice";
import { TaskContextModal } from "../TaskContextModal/TaskContextModal";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import { useSelector } from "react-redux";

export const GlobalModals = () => {
  const dispatch = useAppDispatch();

  const currentContextModalTaskOrRecurring = useSelector(
    selectContextModalTaskOrRecurring
  );
  const isShowAddTaskModal = useAppSelector(selectIsShowAddTaskModal);

  return (
    <>
      {isShowAddTaskModal && (
        <AddTaskModal
          onClose={() => {
            dispatch(setIsShowAddTaskModal(false));
          }}
        ></AddTaskModal>
      )}

      {!!currentContextModalTaskOrRecurring && (
        <TaskContextModal
          taskOrRecurring={currentContextModalTaskOrRecurring.taskOrRecurring}
          dayTimestamp={currentContextModalTaskOrRecurring.dayTimestamp}
          onClose={() => {
            dispatch(setContextModalTask(null));
          }}
        />
      )}
    </>
  );
};
