import { type TimelineAddTaskPromptEntry } from "../timeline.model";
import { Ico } from "../../../../ui-components/Ico";
import styles from "./TimelineAddTaskPrompt.module.scss";
import { setIsShowAddTaskModal } from "../../../../store/ui/uiSlice";
import { useAppDispatch } from "../../../../shared/hooks";

export const TimelineAddTaskPrompt = (props: {
  entry: TimelineAddTaskPromptEntry;
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.TimelineAddTaskPrompt}>
      <div className={styles.msg}>
        <Ico name="schedule" />
        <span>You seem to have some free time between </span>
        <em>
          {props.entry.start.toLocaleTimeString("short")} and{" "}
          {props.entry.end.toLocaleTimeString("short")}
        </em>
        . Would you like to add a task?
      </div>
      <div className={styles.btns}>
        <button
          className="btn s"
          onClick={() => {
            dispatch(setIsShowAddTaskModal(true));
          }}
        >
          <Ico name="add_circle" />
          <div>Add Task</div>
        </button>
      </div>
    </div>
  );
};
