import styles from "./Sidebar.module.scss";

// TODO find out right type for children
export const Sidebar = (props: { width: number; children: any }) => {
  if (props.width <= 0) {
    return <></>;
  }

  return (
    <div className={styles.Sidebar} style={{ width: props.width }}>
      {props.children}
    </div>
  );
};
