import { type Task } from "../../../store/task/taskSlice";
import styles from "./AllDayTask.module.scss";
import { Ico } from "../../../ui-components/Ico";
import { getMaterialIdFromSymbolId } from "../../../util/getMaterialIdFromSymbolId";
import { DEFAULT_TASK_MATERIAL_ICON } from "../../../shared/app.const";
import { useAppDispatch } from "../../../shared/hooks";
import { setContextModalTask } from "../../../store/ui/uiSlice";
import { getColor } from "../../../util/getColor";
import { type RxRecurringNaked } from "../../../rxdb/recurring.schema";

export const AllDayTask = (props: {
  task: Task | RxRecurringNaked;
  day: Date;
}) => {
  const t = props.task;
  const dispatch = useAppDispatch();

  return (
    <div
      onClick={() =>
        dispatch(
          setContextModalTask({
            taskOrRecurring: t,
            dayTimestamp: props.day.getTime(),
          })
        )
      }
      className={styles.AllDayTask}
      style={
        {
          "--task-color": getColor(t.color),
        } as any
      }
    >
      <div className={styles.taskIconWrapper}>
        <Ico
          name={
            getMaterialIdFromSymbolId(t.symbol) ?? DEFAULT_TASK_MATERIAL_ICON
          }
          className="iContrast"
        />
      </div>
      <div className={styles.taskTitle}>{t.title}</div>
    </div>
  );
};
