import Modal from "react-modal";
import styles from "./EditTaskModal.module.scss";
import React from "react";
import { type Task } from "../../store/task/taskSlice";
import { TaskEditForm } from "../TaskEditForm/TaskEditForm";
import { Ico } from "../../ui-components/Ico";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { RecurringUpdatePromptModal } from "../RecurringUpdatePromptModal/RecurringUpdatePromptModal";
import { useEditTaskModal } from "./useEditTaskModal";

export const EditTaskModal = (props: {
  title?: string;
  onCancel: () => void;
  onSubmit: () => void;
  taskOrRecurring: Task | RxRecurringNaked;
  taskDay: Date;
}) => {
  const {
    isShowRecurringUpdatePromptModal,
    setIsShowRecurringUpdateModal,

    newRecurringOwnFields,
    setNewRecurringOwnFields,

    updateTmpTask,
    tmpTask,

    setTmpSubtasks,
    tmpSubtasks,

    updateAllRecurring,
    afterTaskEditSubmit,
    updateSingleInstance,
    updateFutureInstances,
  } = useEditTaskModal(props.taskDay, props.taskOrRecurring, props.onSubmit);

  // useSubtasksByIds
  return (
    <>
      {isShowRecurringUpdatePromptModal && (
        <RecurringUpdatePromptModal
          onClose={() => setIsShowRecurringUpdateModal(false)}
          onUpdateAll={() => updateAllRecurring()}
          onUpdateSingle={() => updateSingleInstance()}
          onUpdateFuture={() => updateFutureInstances()}
        />
      )}

      <Modal
        isOpen={true}
        className={styles.EditTaskModal}
        onRequestClose={() => props.onCancel()}
        shouldCloseOnOverlayClick={true}
      >
        <header>
          {props.title ? props.title : "Edit"} <em>Task</em>
        </header>
        <div onClick={props.onCancel} className={"modalCloseBtn"}>
          <Ico name="close" />
        </div>

        <div className={styles.content}>
          <TaskEditForm
            isEdit={true}
            tmpTask={tmpTask}
            recurringCfg={newRecurringOwnFields}
            tmpSubtasks={tmpSubtasks}
            onSubmit={afterTaskEditSubmit}
            onUpdateTmpTask={updateTmpTask}
            onUpdateTmpSubtasks={setTmpSubtasks}
            onUpdateRecurringCfg={setNewRecurringOwnFields}
          />
        </div>
      </Modal>
    </>
  );
};
