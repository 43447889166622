const getMoreComplexDuration = (now: Date, start: Date, end: Date): number => {
  const totalDurationInMs = end.getTime() - start.getTime();
  const elapsedDuration = now.getTime() - start.getTime();
  return Math.round((elapsedDuration * 100) / totalDurationInMs);
};

export const getTimelineProgress = (
  now: Date,
  start: Date,
  end: Date
): number => {
  return end < now
    ? 100
    : start > now
    ? 0
    : getMoreComplexDuration(now, start, end);
};
