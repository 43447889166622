import { getDayBeforeDate } from "../../util/getDayBeforeDate";
import {
  addRecurringRxDb,
  updateRecurringRxDb,
} from "../../store/recurring/recurringSlice";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import { updateRecurringOccurrenceRxDb } from "../../store/recurringOccurrence/recurringOccurrenceSlice";
import {
  type RecurringOwnFields,
  RecurringType,
  type TmpTask,
} from "../TaskEditForm/taskEdit.model";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import { type RxRecurringOccurrenceNaked } from "../../rxdb/recurringOccurrence.schema";

export const getUpdateFutureRecurringActions = (
  taskDay: Date,
  newRecurringCfg: RecurringOwnFields | null,
  taskModelChanges: TmpTask,
  originalRecurring: RxRecurringNaked,
  recurringOccurrencesForCurrent: RxRecurringOccurrenceNaked[]
): any[] => {
  const actions: any[] = [];

  if (!newRecurringCfg) {
    throw new Error("No recurringCfg");
  }

  // for weekly and daily
  // NOTE: task day will be always on a day that is recurring
  let nextStartDay: Date = taskDay;
  if (newRecurringCfg.recurring_type === RecurringType.Monthly) {
    nextStartDay = new Date(taskDay);
    nextStartDay.setMonth(nextStartDay.getMonth() + newRecurringCfg.interval);
    nextStartDay.setDate(nextStartDay.getDate());
  }

  const dayBeforeDate = getDayBeforeDate(nextStartDay);

  actions.push(
    updateRecurringRxDb({
      id: originalRecurring.id,
      changes: {
        // NOTE only end_day is updated!!!
        end_day: getIsoStrDateOnly(dayBeforeDate),
      },
    })
  );

  // TODO consider end_day edge cases
  // if(!recurringCfg.end_day){

  const newRecurringId = uuid();
  actions.push(
    addRecurringRxDb({
      ...originalRecurring,
      ...taskModelChanges,
      ...newRecurringCfg,
      id: newRecurringId,
      created_at: getDbStrDateFull(),
      modified_at: getDbStrDateFull(),
      start_day: getIsoStrDateOnly(nextStartDay),
    })
  );

  const recurringOccurrencesToUpdate = recurringOccurrencesForCurrent.filter(
    (occurrence) => new Date(occurrence.day) >= taskDay
  );

  recurringOccurrencesToUpdate.forEach((occurrence) => {
    actions.push(
      updateRecurringOccurrenceRxDb({
        id: occurrence.id,
        changes: {
          recurring: newRecurringId,
        },
      })
    );
  });
  return actions;
};
