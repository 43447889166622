import {
  RECEIVED_AT_FIELD,
  type RxGenericReplicationDocumentNaked,
} from "./rxdb.model";

const PROPS_TO_CHECK: (keyof RxGenericReplicationDocumentNaked)[] = [
  "id",
  "user_id",
  RECEIVED_AT_FIELD,
  "replication_revision",
];

type ExtendedDocType<T> = T & RxGenericReplicationDocumentNaked;
type ExtendedDocTypeWithDeleted<T> = ExtendedDocType<T> & { _deleted: boolean };

export const checkValidReplicationDoc = <DT>(
  doc: DT | {}
): ExtendedDocType<DT> => {
  const docKeys: string[] = Object.keys(doc as {});
  if (!PROPS_TO_CHECK.every((prop) => docKeys.includes(prop))) {
    console.log({ doc });
    throw new Error("Replication doc validation failed");
  }
  return doc as ExtendedDocType<DT>;
};

export const checkValidReplicationDocWithDeleted = <DT>(
  doc: DT | {}
): ExtendedDocTypeWithDeleted<DT> => {
  if (!Object.prototype.hasOwnProperty.call(doc, "_deleted")) {
    console.log({ doc });
    throw new Error("Replication doc validation failed: _deleted is false");
  }
  checkValidReplicationDoc(doc);
  return doc as ExtendedDocTypeWithDeleted<DT>;
};
