import { type RxRecurringOccurrenceNaked } from "../rxdb/recurringOccurrence.schema";
import { getIsoStrDateOnly } from "./getIsoStrDateOnly";

export const hasDetachedTasksOnDayForRecurring = (
  allRecurringOccurrences: RxRecurringOccurrenceNaked[],
  day: Date,
  recurringId
): boolean => {
  return !!allRecurringOccurrences.find(
    (occurrence) =>
      occurrence.is_detached &&
      occurrence.recurring === recurringId &&
      occurrence.day === getIsoStrDateOnly(day)
  );
};
