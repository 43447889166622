import { type RxRecurringNaked } from "../rxdb/recurring.schema";
import { type RxTaskNaked } from "../rxdb/task.schema";

type Impossible<K extends keyof any> = {
  [P in K]: never;
};
type NoExtraProperties<T, U extends T = T> = U &
  Impossible<Exclude<keyof U, keyof T>>;

export const getTaskOnlyFieldsFromRecurring = (
  recurring: RxRecurringNaked | RxTaskNaked,
  day: string
): RxTaskNaked => {
  const t: NoExtraProperties<RxTaskNaked> = {
    // defaults for task only fields
    completed_at: null,
    calendar_day_index: null,
    order_index: null,
    events: [],
    is_reminder_detached: false,
    is_hidden: false,
    is_in_inbox: false,
    type: 2,

    // copied from recurring
    id: recurring.id,
    title: recurring.title,
    // TODO fix
    user_id: recurring.user_id,
    replication_revision: recurring.replication_revision,
    created_at: recurring.created_at,
    modified_at: recurring.modified_at,
    day,
    color: recurring.color,
    start_time: recurring.start_time,
    duration: recurring.duration,
    symbol: recurring.symbol,
    is_all_day: recurring.is_all_day,
    received_at: recurring.received_at,
    is_symbol_set: recurring.is_symbol_set,
    subtasks: recurring.subtasks,
    alerts: recurring.alerts,
    note: recurring.note,
    timezone: recurring.timezone,
    // NOTE: should always be false, since we never want to convert a deleted instance
    _deleted: false,
  };
  return t;
};
