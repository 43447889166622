import {
  type RecurringOwnFields,
  type TmpTask,
} from "../TaskEditForm/taskEdit.model";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { type RxRecurringOccurrenceNaked } from "../../rxdb/recurringOccurrence.schema";
import { addTaskRxDb } from "../../store/task/taskSlice";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import {
  addRecurringOccurrenceRxDb,
  updateRecurringOccurrenceRxDb,
} from "../../store/recurringOccurrence/recurringOccurrenceSlice";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { TMP_REV } from "../../rxdb/commonDB.const";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";

export const getUpdateSingleInstanceActions = (
  taskDay: Date,
  newRecurringCfg: RecurringOwnFields | null,
  taskModelChanges: TmpTask,
  originalRecurring: RxRecurringNaked,
  recurringOccurrences: RxRecurringOccurrenceNaked[]
): any[] => {
  const actions: any[] = [];

  if (!taskModelChanges.id) {
    throw new Error("No taskModelChanges.id");
  }

  const recurringOccurrenceForDay = recurringOccurrences.find(
    (occurrence) => occurrence.day === getIsoStrDateOnly(taskDay)
  );

  const newTaskId = uuid();

  actions.push(
    addTaskRxDb({
      ...taskModelChanges,
      // NOTE: it is important to use a new id here since it might otherwise be the one of recurring
      id: newTaskId,
      user_id: originalRecurring.user_id,
      created_at: getDbStrDateFull(),
      modified_at: getDbStrDateFull(),
    })
  );

  if (recurringOccurrenceForDay) {
    actions.push(
      updateRecurringOccurrenceRxDb({
        id: recurringOccurrenceForDay.id,
        changes: {
          is_detached: true,
          detached_task: newTaskId,
        },
      })
    );
  } else {
    if (!newRecurringCfg) {
      throw new Error("No updatedRecurringCfg");
    }
    actions.push(
      addRecurringOccurrenceRxDb({
        id: uuid(),
        user_id: originalRecurring.user_id,
        order_index: taskModelChanges.order_index,
        created_at: getDbStrDateFull(),
        modified_at: getDbStrDateFull(),
        recurring: originalRecurring.id,
        day: getIsoStrDateOnly(taskDay),
        subtask_occurrences: [],
        replication_revision: TMP_REV,
        completed_at: taskModelChanges.completed_at ?? null,
        detached_task: newTaskId,
        is_detached: true,
        _deleted: false,
      })
    );
  }

  return actions;
};
