import Modal from "react-modal";

export const RecurringUpdatePromptModal = (props: {
  onClose: () => void;
  onUpdateAll: () => void;
  onUpdateSingle: () => void;
  onUpdateFuture: () => void;
}) => {
  return (
    <Modal
      isOpen={true}
      className="A"
      onRequestClose={() => props.onClose()}
      shouldCloseOnOverlayClick={true}
    >
      <p>This is a recurring task.</p>
      <button type="button" className="btn-neutral" onClick={props.onUpdateAll}>
        Update all
      </button>
      <button
        type="button"
        className="btn-neutral"
        onClick={props.onUpdateFuture}
      >
        Update all future tasks
      </button>
      <button
        type="button"
        className="btn-neutral"
        onClick={props.onUpdateSingle}
      >
        Update this instance only
      </button>
    </Modal>
  );
};
