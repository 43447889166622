import { type TimelineEntry, TimelineEntryType } from "./timeline.model";
import { type RxTaskNaked } from "../../../rxdb/task.schema";
import { getTimelineProgress } from "./getTimelineProgress";
import { dbStartTimeAndDateStrToDate } from "../../../util/dbStartTimeAndDateStrToDate";
import { type RxRecurringNaked } from "../../../rxdb/recurring.schema";
import { getIsoStrDateOnly } from "../../../util/getIsoStrDateOnly";

const GAP_PROMPT_MIN_DURATION_IN_MS = 60 * 60 * 1000;
const GAP_BUFFER_MIN_DURATION_IN_MS = 60 * 1000;

export const generateTimelineEntries = (
  tasksForDay: (RxTaskNaked | RxRecurringNaked)[],
  dayDate: Date,
  now: Date
): TimelineEntry[] => {
  const entries: TimelineEntry[] = [];
  const strDateForDay = getIsoStrDateOnly(dayDate);

  tasksForDay
    .filter(
      (t) => !!t.start_time && typeof t.duration === "number" && !t.is_all_day
    )
    .sort((a, b) => {
      if ((a.start_time as number) < (b.start_time as number)) {
        return -1;
      } else if ((a.start_time as number) > (b.start_time as number)) {
        return 1;
      } else {
        return (a.duration as number) - (b.duration as number);
      }
    })
    .forEach((task) => {
      if (!task.start_time) {
        return;
      }
      const startDate = dbStartTimeAndDateStrToDate(
        task.start_time,
        strDateForDay
      );
      const durationInMin = task.duration as number;
      const prevEntry = entries[entries.length - 1];
      const endDate = new Date(startDate.getTime() + durationInMin * 60 * 1000);

      const progress = getTimelineProgress(now, startDate, endDate);

      if (prevEntry) {
        const gapInMS = startDate.getTime() - prevEntry.end.getTime();
        const isInThePast = startDate < now;
        const start = prevEntry.end;
        const end = startDate;
        const durationInMin = Math.round(gapInMS / 60000);

        const gapProgress = getTimelineProgress(now, start, end);

        if (gapInMS > GAP_PROMPT_MIN_DURATION_IN_MS && !isInThePast) {
          entries.push({
            type: TimelineEntryType.ADD_TASK_PROMPT,
            durationInMin,
            start,
            end,
            progress: gapProgress,
            color: prevEntry.color,
          });
        } else if (gapInMS > GAP_BUFFER_MIN_DURATION_IN_MS) {
          entries.push({
            type: TimelineEntryType.GAP_MSG,
            durationInMin,
            start,
            end,
            isInThePast,
            progress: gapProgress,
            color: prevEntry.color,
          });
        }
      }
      const isOverlapBefore =
        prevEntry?.type === TimelineEntryType.TASK &&
        prevEntry.end.getTime() > startDate.getTime();

      if (
        isOverlapBefore &&
        prevEntry &&
        prevEntry.type === TimelineEntryType.TASK
      ) {
        prevEntry.isOverlapAfter = true;
      }

      entries.push({
        type: TimelineEntryType.TASK,
        taskOrRecurring: task,
        durationInMin,
        start: startDate,
        end: endDate,
        isOverlapAfter: false,
        isOverlapBefore,
        progress,
        color: task.color,
      });
    });

  return entries;
};
