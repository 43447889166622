import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { RecurringType } from "../TaskEditForm/taskEdit.model";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { TaskType } from "../../shared/app.const";

type RecurringNakedWithoutSome = Omit<
  RxRecurringNaked,
  "id" | "user_id" | "created_at" | "modified_at" | "start_time" | "day"
>;
const SHARED: Omit<
  RecurringNakedWithoutSome,
  "title" | "symbol" | "color" | "type"
> = {
  recurring_type: RecurringType.Daily,
  interval: 1,
  duration: 1,
  start_day: getIsoStrDateOnly(new Date()),
  end_day: null,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
  replication_revision: "",
  is_symbol_set: false,
  is_all_day: false,
  note: "",
  _deleted: false,
  timezone: null,
  subtasks: [],
  alerts: [],
};

export const ONBOARDING_TASK_WAKE_UP: RecurringNakedWithoutSome = {
  ...SHARED,
  title: "Wake up",
  symbol: "sun.haze.fill",
  color: "",
  type: TaskType.recurringMorning,
};

export const ONBOARDING_TASK_SLEEP: RecurringNakedWithoutSome = {
  ...SHARED,
  title: "Go to bed",
  symbol: "",
  // TODO add correct color name
  color: "",
  type: TaskType.recurringNight,
};
