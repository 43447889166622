import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../store";
import { getNrOfDaysSinceLinuxEpoch } from "../../util/getNrOfDaysSinceLinuxEpoch";
import { dbDaysToDate } from "../../util/dbDaysToDate";
import { type Task } from "../task/taskSlice";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { type RxTaskNaked } from "../../rxdb/task.schema";

export interface UiState {
  selectedEpochDay: number;
  isShowAddTaskModal: boolean;
  addTaskModalDefaults: Partial<RxTaskNaked> | null;
  isShowSidebar: boolean;
  contextModalTaskOrRecurring: {
    taskOrRecurring: Task | RxRecurringNaked;
    dayTimestamp: number;
  } | null;
}

const initialState: UiState = {
  selectedEpochDay: getNrOfDaysSinceLinuxEpoch(new Date()),
  isShowAddTaskModal: false,
  isShowSidebar: false,
  contextModalTaskOrRecurring: null,
  addTaskModalDefaults: null,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSelectedEpochDay: (state, action: PayloadAction<number>) => {
      state.selectedEpochDay = action.payload;
    },
    setIsShowAddTaskModal: (state, action: PayloadAction<boolean>) => {
      state.isShowAddTaskModal = action.payload;
    },
    setIsShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.isShowSidebar = action.payload;
    },
    toggleIsShowAddTaskModal: (
      state,
      action: PayloadAction<Partial<RxTaskNaked> | undefined | null>
    ) => {
      state.isShowAddTaskModal = !state.isShowAddTaskModal;
      state.addTaskModalDefaults = action.payload ?? null;
    },
    toggleIsShowSidebar: (state) => {
      state.isShowSidebar = !state.isShowSidebar;
    },
    setContextModalTask: (
      state,
      action: PayloadAction<{
        taskOrRecurring: Task | RxRecurringNaked;
        dayTimestamp: number;
      } | null>
    ) => {
      state.contextModalTaskOrRecurring = action.payload;
    },
  },
});

export const {
  setSelectedEpochDay,
  setIsShowAddTaskModal,
  setContextModalTask,
  toggleIsShowAddTaskModal,
  toggleIsShowSidebar,
} = uiSlice.actions;

export const selectIsShowAddTaskModal = (state: RootState): boolean =>
  state.ui.isShowAddTaskModal;

export const selectContextModalTaskOrRecurring = (
  state: RootState
): {
  taskOrRecurring: Task | RxRecurringNaked;
  dayTimestamp: number;
} | null => state.ui.contextModalTaskOrRecurring;

export const selectSelectedEpochDay = (state: RootState): number =>
  state.ui.selectedEpochDay;

export const selectSelectedEpochDayAsDate = (state: RootState): Date =>
  dbDaysToDate(state.ui.selectedEpochDay);

export const selectIsShowSidebar = (state: RootState): boolean =>
  state.ui.isShowSidebar;

export const selectAddTaskModalDefaults = (
  state: RootState
): Partial<RxTaskNaked> | null => state.ui.addTaskModalDefaults;

export default uiSlice.reducer;
