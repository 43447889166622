import { type RxRecurringNaked } from "../rxdb/recurring.schema";
import { isRecurringOnDay } from "./isRecurringOnDay";
import { type RxRecurringOccurrenceNaked } from "../rxdb/recurringOccurrence.schema";
import { hasDetachedTasksOnDayForRecurring } from "./hasDetachedTasksOnDayForRecurring";

export const getRecurringForDayWithoutDetached = (
  recurringCfgs: RxRecurringNaked[],
  recurringOccurrences: RxRecurringOccurrenceNaked[],
  day: string
) => {
  const dayDate = new Date(day);
  return recurringCfgs.filter(
    (recurringCfg) =>
      isRecurringOnDay(recurringCfg, dayDate) &&
      !hasDetachedTasksOnDayForRecurring(
        recurringOccurrences,
        dayDate,
        recurringCfg.id
      )
  );
};
