import {
  lastOfArray,
  type RxDatabase,
  type RxReplicationPullStreamItem,
  type RxReplicationWriteToMasterRow,
  type WithDeleted,
} from "rxdb";
import { Subject } from "rxjs";
import {
  type CheckpointType,
  RECEIVED_AT_FIELD,
  type RxAllCollections,
  type RxGenericReplicationDocumentNaked,
} from "./rxdb.model";
import { replicateRxCollection } from "rxdb/plugins/replication";
import { supabase, SUPABASE_URL } from "../api/supabaseClient";
import {
  type ReplicationPullHandlerResult,
  type ReplicationPushHandlerResult,
} from "rxdb/dist/types/types/plugins/replication";
import { getDbStrDateFull } from "../util/getDbStrDateFull";
import { SupTableNames } from "../api/tableNames.const";
import { type RxCollection } from "rxdb/dist/types/types";
import {
  checkValidReplicationDoc,
  checkValidReplicationDocWithDeleted,
} from "./checkValidReplicationDoc";
import { type RxTaskNaked } from "./task.schema";
import { type RxRecurringNaked } from "./recurring.schema";
import { type RxRecurringOccurrenceNaked } from "./recurringOccurrence.schema";

const CHECK_REMOTE_UPDATES_INTERVAL = 5 * 1000;

// DOC: https://rxdb.info/replication.html
export async function startReplicationForAll(
  database: RxDatabase<RxAllCollections>
) {
  return await Promise.all([
    startReplication<RxTaskNaked>(SupTableNames.Task, database.task),
    startReplication<RxRecurringNaked>(
      SupTableNames.Recurring,
      database.recurring
    ),
    startReplication<RxRecurringOccurrenceNaked>(
      SupTableNames.RecurringOccurrence,
      database.recurring_occurrence
    ),
  ]).catch((error) => {
    console.error(error);
  });
}

export function startReplication<
  RxDocType extends RxGenericReplicationDocumentNaked
>(tableName: SupTableNames, collection: RxCollection<RxDocType>) {
  const pullStream$ = new Subject<
    RxReplicationPullStreamItem<RxDocType, CheckpointType>
  >();

  pullStream$.subscribe((v) => {
    console.log("R:pullStream$" + tableName, v);
  });

  // lazySetInterval(() => {
  //   pullStream$.next("RESYNC");
  // }, CHECK_REMOTE_UPDATES_INTERVAL);

  const replicationState = replicateRxCollection<RxDocType, CheckpointType>({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    collection,
    replicationIdentifier: `sup-replication-${tableName}-${SUPABASE_URL}`,
    deletedField: "_deleted",
    pull: {
      handler: createReplicationPullHandler<RxDocType>(tableName),
      batchSize: 10,
      stream$: pullStream$.asObservable(),
    },
    push: {
      batchSize: 1,
      handler: createReplicationPushHandler<RxDocType>(tableName),
    },
  });

  replicationState.error$.subscribe((err) => {
    console.error(`## replicationState.error$(${tableName}):`);
    console.dir(err);
  });

  return replicationState;
}

export function createReplicationPullHandler<
  RxDocType extends RxGenericReplicationDocumentNaked
>(
  tableName: SupTableNames
): (
  lastCheckpoint: CheckpointType,
  batchSize: number
) => Promise<
  ReplicationPullHandlerResult<WithDeleted<RxDocType>, CheckpointType>
> {
  return async function createReplicationPullHandler(
    lastCheckpoint: CheckpointType,
    batchSize: number
  ): Promise<
    ReplicationPullHandlerResult<WithDeleted<RxDocType>, CheckpointType>
  > {
    // NOTE: we add one millisecond to avoid problems when precision is higher in the backend than in the frontend date
    // e.g.: 2023-04-13T10:23:10.541Z < 2023-04-13T10:23:10.5412423Z
    const minTimestamp = lastCheckpoint
      ? lastCheckpoint.checkpointUpdate + 1
      : 0;
    console.log(`PULL ${tableName}`, getDbStrDateFull(minTimestamp));
    try {
      // TODO check if request.or(filters: "and(received_at.eq.\(received_at),key.gt.\(key))") is needed
      // see: https://www.notion.so/Sync-Edge-Cases-358ffabda01c4129bc74f07a94419788?d=d5b80af44aa74336b052f8157d1f1a38#47ee89ff740f4c279cbd2fd75a051fe3
      const { data, error } = await supabase
        .from(tableName)
        .select()
        .gt(RECEIVED_AT_FIELD, getDbStrDateFull(minTimestamp))
        .order(RECEIVED_AT_FIELD, { ascending: true })
        .order("id", { ascending: true })
        .limit(batchSize);

      if (error) {
        console.error(error);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(error as any);
      }

      // NOTE we're only checking last doc type for efficiency
      const docs = data as unknown as WithDeleted<RxDocType>[];
      console.log(tableName, minTimestamp, docs);

      const lastDoc = lastOfArray(docs);

      if (!lastDoc) {
        // no new docs
        return {
          documents: docs,
          checkpoint: lastCheckpoint,
        };
      }
      checkValidReplicationDocWithDeleted(lastDoc);

      if (
        !lastDoc[RECEIVED_AT_FIELD] ||
        typeof lastDoc[RECEIVED_AT_FIELD] !== "string"
      ) {
        throw new Error("No value received for received_at for last doc :(");
      }
      console.log(`PULL ${tableName}`, docs);

      return {
        documents: docs,
        checkpoint: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          name: (lastDoc as any)?.title ?? "missing_checkpoint_name",
          checkpointUpdate: new Date(lastDoc[RECEIVED_AT_FIELD]).getTime(),
        },
      };
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
}

export function createReplicationPushHandler<
  RxDocType extends RxGenericReplicationDocumentNaked
>(
  tableName: SupTableNames
): (
  rows: RxReplicationWriteToMasterRow<RxDocType>[]
) => Promise<ReplicationPushHandlerResult<WithDeleted<RxDocType>>> {
  // this gets called with a list of all local changes for the model in question
  return async function replicationPushHandler(
    rows: RxReplicationWriteToMasterRow<RxDocType>[]
  ): Promise<ReplicationPushHandlerResult<WithDeleted<RxDocType>>> {
    console.log(`PUSH ${tableName}`);
    console.log(`PUSH ${tableName}: rows:`, rows);

    if (rows.length !== 1) {
      throw new Error("# pushHandler(): too many push documents");
    }
    const row = rows[0];
    const oldDoc = row.assumedMasterState;
    const doc = row.newDocumentState;
    console.log(`PUSH ${tableName}:`, { doc, oldDoc });

    // insert since we assume there should be nothing on server
    if (!oldDoc) {
      const withNoRequiredReplicationRevisionField = {
        ...doc,
        replication_revision: doc.replication_revision as string,
      };
      const { error } = await supabase
        .from(tableName)
        // NOTE: we have to trust that table matches type :)
        .insert([withNoRequiredReplicationRevisionField as any]);

      if (error) {
        console.log(`PUSH ${tableName}: Insert Error:`);
        console.error(error);

        // we have an insert conflict (there possibly was a document)
        // => we download the doc and return it for conflict resolution
        const conflictDocRes = await supabase
          .from(tableName)
          .select()
          .eq("id", doc.id)
          .limit(1);
        if (!conflictDocRes.data?.length) {
          // NOTE: will retry again after a while since master doc (taskOrRecurring,recurring) might not be created yet
          console.log({ conflictDocRes });
          throw new Error("No conflict Doc Data - something else went wrong?");
        }
        return [
          checkValidReplicationDoc<WithDeleted<RxDocType>>(
            conflictDocRes.data[0]
          ),
        ];
      } else {
        return [];
      }
    }

    // update
    // NOTE: normally we could also attempt to update first like so:
    const { data, error } = await supabase
      .from(tableName)
      .update(doc)
      .eq("id", doc.id)
      .eq("replication_revision", oldDoc.replication_revision)
      .select();

    if (error) {
      console.log(`PUSH ${tableName}: simple update error`, { error });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      throw new Error(error as any);
    }
    if (data?.length > 1) {
      throw new Error("Too many documents returned from server");
    }
    if (data?.length === 1) {
      console.log(`PUSH ${tableName}: simple update success`, data[0]);
      // use server doc anyway, since it contains the updated timestamp
      return [checkValidReplicationDoc<WithDeleted<RxDocType>>(data[0])];
    }

    console.log(
      `PUSH ${tableName}: simple update failed => fetching server doc to double check `
    );
    // always download document first to be safer
    const serverDocs = await supabase
      .from(tableName)
      .select()
      .eq("id", doc.id)
      .limit(1);

    if (!serverDocs.data?.length) {
      throw new Error("Doc does not exist on server for unknown reasons");
      // doc dos not yet exist
      // console.log(`PUSH ${tableName}: trying to insert missing doc`);
      // await supabase.from(tableName).insert([doc]);
      return [];
    }

    const serverDoc: WithDeleted<RxDocType> = checkValidReplicationDoc<
      WithDeleted<RxDocType>
    >(serverDocs.data[0]);
    console.log(`PUSH ${tableName}:`, { serverDoc });

    // server doc wins, when didn't have the last version locally
    // console.log(serverDoc.received_at === oldDoc.received_at,);

    if (serverDoc.replication_revision !== oldDoc.replication_revision) {
      console.log(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `PUSH ${tableName}: did not have last server doc before update => serverDoc to conflictResolution => wins | S:${serverDoc.replication_revision} O:${oldDoc.replication_revision}`
      );
      return [serverDoc];
    } else {
      console.log(`PUSH ${tableName}: update required`);
      // local doc wins
      const { data, error } = await supabase
        .from(tableName)
        .update(doc)
        .match({
          id: doc.id,
        })
        .select();
      if (error) {
        console.log(`PUSH ${tableName}: update error:`);
        console.error(error);
        console.dir(data);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(error as any);
      }
      // use server doc anyway, since it contains the updated timestamp

      return [checkValidReplicationDoc<WithDeleted<RxDocType>>(data[0])];
    }
  };
}
