import classNames from "classnames";
import styles from "./Ico.module.scss";
import {
  DEFAULT_TASK_MATERIAL_ICON,
  DEFAULT_TASK_SYMBOL_ID,
} from "../shared/app.const";

export const Ico = ({
  name,
  className,
}: {
  name: string;
  className?: string;
}) => {
  return (
    <i className={classNames([styles.Ico, className])}>
      {/* {name === "TMP_SYMBOL" ? "checklist" : name} */}
      {name === DEFAULT_TASK_SYMBOL_ID ? DEFAULT_TASK_MATERIAL_ICON : name}
      {/* {name[0]?.toUpperCase() ?? ":("} */}
    </i>
  );
};
