import { useTasksForDay } from "../../store/task/taskSlice";
import styles from "./AllDayTasks.module.scss";
import { AllDayTask } from "./AllDayTask/AllDayTask";
import { useRecurringCfgsForDayWithoutDetached } from "../../store/recurring/recurringSlice";
import { type RxTaskNaked } from "../../rxdb/task.schema";

const DEFAULT_ORDER_INDEX = 9999;

export const AllDayTasks = (props: { day: Date }) => {
  const tasksForDay = useTasksForDay(props.day);
  const recurringCfgs = useRecurringCfgsForDayWithoutDetached(props.day);

  const allDayTasks = [...tasksForDay, ...recurringCfgs]

    .filter((t) => t.is_all_day)
    .sort((a, b) => {
      const aOI = (a as RxTaskNaked).order_index ?? DEFAULT_ORDER_INDEX;
      const bOI = (b as RxTaskNaked).order_index ?? DEFAULT_ORDER_INDEX;
      return aOI === bOI
        ? // sort by creation date for now
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        : aOI - bOI;
    });
  return allDayTasks.length > 0 ? (
    <section className={styles.AllDayTasks}>
      {allDayTasks.map((t) => (
        <AllDayTask task={t} key={t.id} day={props.day} />
      ))}
    </section>
  ) : (
    <></>
  );
};
