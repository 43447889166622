import iconMap from "../../shared/iconMapReduced.json";
import Fuse from "fuse.js";

const OPTS = {
  isCaseSensitive: false,
  includeScore: false,
  shouldSort: true,
  includeMatches: false,
  findAllMatches: false,
  minMatchCharLength: 1,
  location: 0,
  threshold: 0.6,
  distance: 100,
  useExtendedSearch: false,
  ignoreLocation: false,
  ignoreFieldNorm: false,
  fieldNormWeight: 1,
  keys: ["name"],
};

interface SymbolListEntry {
  materialId: string;
  name: string;
  symbolId: string;
}

console.log(iconMap);
const asArray = Object.keys(iconMap).map((symbolId) => {
  const entry = iconMap[symbolId];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    ...entry,
    symbolId,
  } as SymbolListEntry;
});

export const getSymbolIdFromTitle = (title: string): string | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const fuse = new Fuse(asArray, OPTS);
  const r = fuse.search(title);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  return (r[0]?.item as any)?.symbolId || undefined;
};
