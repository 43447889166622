import {
  type Task,
  useFirstFreePeriodStartForDay,
} from "../../store/task/taskSlice";
import Modal from "react-modal";
import React, { useState } from "react";
import styles from "./TaskContextModal.module.scss";
import { Ico } from "../../ui-components/Ico";
import { formatTaskTimeframe } from "../../util/formatTaskTimeframe";
import { GridBtn } from "../../ui-components/GridBtn";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import classNames from "classnames";
import { EditTaskModal } from "../EditTaskModal/EditTaskModal";
import { AddTaskModal } from "../AddTaskModal/AddTaskModal";
import { getMaterialIdFromSymbolId } from "../../util/getMaterialIdFromSymbolId";
import {
  DEFAULT_TASK_DURATION,
  DEFAULT_TASK_MATERIAL_ICON,
} from "../../shared/app.const";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { RecurringDeletePromptModal } from "../RecurringDeletePromptModal/RecurringDeletePromptModal";
import { useTaskContextModal } from "./useTaskContextModal";
import { getColor } from "../../util/getColor";
import { type RxTaskNaked } from "../../rxdb/task.schema";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { strTimeToDbFloat } from "../../util/strTimeToDbFloat";

export const TaskContextModal = (props: {
  taskOrRecurring: Task | RxRecurringNaked;
  dayTimestamp: number;
  onClose: () => void;
}) => {
  const taskDay: Date = new Date(props.dayTimestamp);

  const [isShowEditTaskModal, setIsShowEditTaskModal] = useState(false);
  const [isShowCopyTaskModal, setIsShowCopyTaskModal] = useState(false);
  const [isShowRecurringDeletePromptModal, setIsShowRecurringDeleteModal] =
    useState(false);
  const [isShowAddInboxTaskModal, setIsShowAddInboxTaskModal] = useState(false);

  const {
    deleteAllRecurring,
    deleteSingleRecurring,
    deleteFutureRecurring,
    taskOrRecurringLive,
    isRecurring,
    subtasks,
    completedAt,
    updateSubtasks,
    deleteTask,
    toggleCompletedAt,
  } = useTaskContextModal(taskDay, props.taskOrRecurring, props.onClose);

  const firstPossibleStartTime = useFirstFreePeriodStartForDay(
    taskDay,
    new Date(),
    taskOrRecurringLive.duration ?? DEFAULT_TASK_DURATION
  );

  return (
    <Modal
      isOpen={true}
      className={styles.TaskContextModal}
      onRequestClose={() => props.onClose()}
      shouldCloseOnOverlayClick={true}
    >
      {isShowRecurringDeletePromptModal && (
        <RecurringDeletePromptModal
          onClose={() => setIsShowRecurringDeleteModal(false)}
          onDeleteAll={() => deleteAllRecurring()}
          onDeleteSingle={() => deleteSingleRecurring()}
          onDeleteFuture={() => deleteFutureRecurring()}
        />
      )}
      {isShowEditTaskModal && (
        <EditTaskModal
          taskDay={taskDay}
          onCancel={() => setIsShowEditTaskModal(false)}
          onSubmit={() => props.onClose()}
          taskOrRecurring={taskOrRecurringLive}
        />
      )}
      {isShowCopyTaskModal && (
        <AddTaskModal
          onClose={() => {
            setIsShowCopyTaskModal(false);
            props.onClose();
          }}
          copiedTaskOrRecurring={taskOrRecurringLive}
        />
      )}

      {isShowAddInboxTaskModal && (
        <EditTaskModal
          title="Add"
          taskDay={taskDay}
          onCancel={() => setIsShowAddInboxTaskModal(false)}
          onSubmit={() => props.onClose()}
          taskOrRecurring={{
            ...taskOrRecurringLive,
            day: getIsoStrDateOnly(taskDay),
            is_in_inbox: false,
            start_time: strTimeToDbFloat(firstPossibleStartTime),
          }}
        />
      )}

      <div
        onClick={props.onClose}
        className={classNames(["modalCloseBtn", styles.closeBtn])}
      >
        <Ico name="close" />
      </div>

      <div
        style={
          {
            "--task-color": getColor(taskOrRecurringLive.color),
            "--grid-btn-fg-color": getColor(taskOrRecurringLive.color),
          } as any
        }
      >
        <header className={styles.taskHeader}>
          <div className={styles.taskIcon}>
            <Ico
              name={
                getMaterialIdFromSymbolId(taskOrRecurringLive.symbol) ??
                DEFAULT_TASK_MATERIAL_ICON
              }
            />
          </div>

          <div className={styles.taskHeaderMiddle}>
            <div
              title={JSON.stringify(taskOrRecurringLive, null, 2)}
              className={styles.taskTimeframe}
            >
              {/* TODO check if this works for recurring */}
              {formatTaskTimeframe(
                taskOrRecurringLive as RxTaskNaked,
                taskDay
              )}{" "}
              {isRecurring && <Ico name="replay" className="izXs" />}
            </div>
            <div className={styles.taskTitle}>{taskOrRecurringLive.title}</div>
          </div>
        </header>

        <div className={styles.content}>
          {subtasks.length > 0 && (
            <section className={styles.subtasks}>
              {subtasks.map((st, i) => (
                <div
                  className={classNames(styles.subtask, {
                    [styles.isChecked]: !!st.completed_at,
                  })}
                  key={i}
                  onClick={(ev) => {
                    updateSubtasks(st.id, {
                      completed_at: !st.completed_at
                        ? getDbStrDateFull()
                        : null,
                    });
                    ev.preventDefault();
                  }}
                >
                  <input
                    type="checkbox"
                    checked={!!st.completed_at}
                    onChange={(ev) => {
                      // if (!!ev.target.value) {
                      // }
                    }}
                  />
                  <div className={styles.subtaskTitle}>{st.title}</div>
                </div>
              ))}
            </section>
          )}

          <div className={styles.topRow}>
            <GridBtn
              title="Delete"
              ico="delete"
              onClick={() => {
                if (isRecurring) {
                  setIsShowRecurringDeleteModal(true);
                } else {
                  deleteTask();
                }
              }}
            />
            <GridBtn
              title="Copy"
              ico="content_copy"
              onClick={() => {
                setIsShowCopyTaskModal(true);
              }}
            />
            <GridBtn
              title={completedAt ? "Complete" : "Incomplete"}
              ico="check"
              onClick={() => {
                toggleCompletedAt();
              }}
            />
            {(taskOrRecurringLive as RxTaskNaked).is_in_inbox && (
              <GridBtn
                title="Add"
                ico="add"
                onClick={() => setIsShowAddInboxTaskModal(true)}
              />
            )}
          </div>
          <div className={styles.bottomRow}>
            <GridBtn
              title="Edit Task"
              ico="edit"
              isHorizontal={true}
              onClick={() => setIsShowEditTaskModal(true)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
