import { type RxRecurringNaked } from "../rxdb/recurring.schema";
import { RecurringType } from "../features/TaskEditForm/taskEdit.model";
import { getDiffInDays } from "./getDiffInDays";
import { getDiffInWeeks } from "./getDiffInWeeks";
import { getDiffInMonths } from "./getDiffInMonths";

// NOTE: week starts at sunday in js
const WEEKDAY_MAP = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as (keyof RxRecurringNaked)[];

export const isRecurringOnDay = (
  recurringCfg: RxRecurringNaked,
  dayToCheckDate: Date
) => {
  const startDayDate = new Date(recurringCfg.start_day);
  if (
    startDayDate > dayToCheckDate ||
    (recurringCfg.end_day && new Date(recurringCfg.end_day) < dayToCheckDate)
  ) {
    return false;
  }

  const interval = recurringCfg.interval ?? 1;

  switch (recurringCfg.recurring_type) {
    case RecurringType.Daily: {
      if (interval === 1) {
        return true;
      }
      const diffInDays = getDiffInDays(startDayDate, dayToCheckDate);
      return (
        // start date is not in the future
        diffInDays >= 0 && diffInDays % interval === 0
      );
    }
    case RecurringType.Weekly: {
      const weekday = dayToCheckDate.getDay();
      const dayProp = WEEKDAY_MAP[weekday];
      if (interval === 1) {
        return !!recurringCfg[dayProp];
      }

      const diffInWeeks = getDiffInWeeks(startDayDate, dayToCheckDate);
      return (
        // start date is not in the future
        diffInWeeks >= 0 &&
        diffInWeeks % interval === 0 &&
        !!recurringCfg[dayProp]
      );
    }
    case RecurringType.Monthly: {
      const startDayDay = startDayDate.getDate();
      const daysInCurrentMonth = new Date(
        dayToCheckDate.getFullYear(),
        dayToCheckDate.getMonth() + 1,
        0
      ).getDate();

      const isCreationDayThisMonth =
        // checking for weird edge case when the month has fewer days total than the startDay
        startDayDay >= daysInCurrentMonth
          ? daysInCurrentMonth <= dayToCheckDate.getDate()
          : dayToCheckDate.getDate() === startDayDate.getDate();
      if (interval === 1) {
        return isCreationDayThisMonth;
      }

      const diffInMonth = getDiffInMonths(startDayDate, dayToCheckDate);
      return (
        isCreationDayThisMonth &&
        // start date is not in the future
        diffInMonth >= 0 &&
        diffInMonth % interval === 0
      );
    }
  }
  return false;
};
