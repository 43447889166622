import { useAppDispatch } from "../../shared/hooks";
import { isRecurringTask } from "../../util/isRecurringTask";
import {
  removeRecurringOccurrencesRxDb,
  useRecurringOccurrencesByRecurringId,
} from "../../store/recurringOccurrence/recurringOccurrenceSlice";
import {
  type RecurringOwnFields,
  type TmpSubtask,
  type TmpTask,
} from "../TaskEditForm/taskEdit.model";
import { getRecurringOnlyFields } from "../../util/getRecurringOnlyFields";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { getTaskOnlyFieldsFromRecurring } from "../../util/getTaskOnlyFieldsFromRecurring";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { dirtyDeepCopy } from "../../util/dirtyDeepCopy";
import { usePrevious } from "../../shared/usePrevious";
import {
  addRecurringRxDb,
  removeRecurringRxDb,
  updateRecurringRxDb,
} from "../../store/recurring/recurringSlice";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import {
  addTaskRxDb,
  removeTaskRxDb,
  type Task,
  updateTaskRxDb,
} from "../../store/task/taskSlice";
import { useState } from "react";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import { getUpdateSingleInstanceActions } from "./getUpdateSingleInstanceActions";
import { getUpdateFutureRecurringActions } from "./getUpdateFutureRecurringActions";
import { type RxTaskNaked } from "../../rxdb/task.schema";

export const useEditTaskModal = (
  taskDay: Date,
  taskOrRecurring: Task | RxRecurringNaked,
  onSubmit: () => void
) => {
  const dispatch = useAppDispatch();
  const isRecurring = isRecurringTask(taskOrRecurring);
  const userId = taskOrRecurring.user_id;
  const recurringOccurrencesForCurrent = useRecurringOccurrencesByRecurringId(
    taskOrRecurring.id
  );

  const [newRecurringOwnFields, setNewRecurringOwnFields] =
    useState<RecurringOwnFields | null>(
      isRecurring
        ? getRecurringOnlyFields(taskOrRecurring as RxRecurringNaked)
        : null
    );

  const [isShowRecurringUpdatePromptModal, setIsShowRecurringUpdateModal] =
    useState(false);

  const [tmpTask, setTmpTask] = useState<TmpTask>(
    isRecurring
      ? getTaskOnlyFieldsFromRecurring(
          taskOrRecurring,
          getIsoStrDateOnly(taskDay)
        )
      : (dirtyDeepCopy(taskOrRecurring) as RxTaskNaked)
  );

  const [tmpSubtasks, setTmpSubtasks] = useState<TmpSubtask[]>(
    // TODO make it work for recurring
    dirtyDeepCopy((taskOrRecurring.subtasks as TmpSubtask[]) ?? [])
  );
  const recurringCfgBeforeAsStr: string | undefined = usePrevious(
    JSON.stringify(newRecurringOwnFields)
  );

  const updateTmpTask = (updateProps: Partial<TmpTask>) => {
    setTmpTask({
      ...tmpTask,
      ...updateProps,
    });
  };

  const updateAllRecurring = () => {
    if (!newRecurringOwnFields) {
      throw new Error("No updatedRecurringCfg");
    }
    void dispatch(
      updateRecurringRxDb({
        id: tmpTask.id,
        changes: {
          ...newRecurringOwnFields,
          ...tmpTask,
          subtasks: tmpSubtasks,
          modified_at: getDbStrDateFull(),
        },
      })
    );
    onSubmit();
  };

  const convertTaskToRecurring = () => {
    if (!newRecurringOwnFields) {
      throw new Error("No updatedRecurringCfg");
    }
    void dispatch(removeTaskRxDb(tmpTask.id));
    void dispatch(
      addRecurringRxDb({
        ...newRecurringOwnFields,
        ...tmpTask,
        id: uuid(),
        user_id: userId,
        created_at: getDbStrDateFull(),
        subtasks: tmpSubtasks,
        modified_at: getDbStrDateFull(),
      })
    );
  };

  const convertRecurringToTask = () => {
    void dispatch(
      removeRecurringOccurrencesRxDb(
        recurringOccurrencesForCurrent.map((occ) => occ.id)
      )
    );
    void dispatch(
      addTaskRxDb({
        ...getTaskOnlyFieldsFromRecurring(
          taskOrRecurring,
          getIsoStrDateOnly(taskDay)
        ),
        subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
        id: uuid(),
      })
    );
    void dispatch(removeRecurringRxDb(taskOrRecurring.id));
  };

  const afterTaskEditSubmit = () => {
    if (!tmpTask.id) {
      throw new Error(
        "Tmp taskOrRecurring key is needed to created taskOrRecurring with sub tasks"
      );
    }
    if (tmpTask.title.length <= 1) {
      console.warn("Task title length should at least be 1");
      // TODO inform user somehow
      return;
    }

    if (isRecurring) {
      console.log("updateInDB", { recurringCfg: newRecurringOwnFields });
      if (newRecurringOwnFields === null) {
        convertRecurringToTask();
        onSubmit();
      } else {
        if (JSON.stringify(newRecurringOwnFields) === recurringCfgBeforeAsStr) {
          setIsShowRecurringUpdateModal(true);
        } else {
          updateAllRecurring();
          onSubmit();
        }
      }
    } else if (
      !isRecurring &&
      newRecurringOwnFields &&
      newRecurringOwnFields.recurring_type !== null
    ) {
      convertTaskToRecurring();
      onSubmit();
    } else {
      void dispatch(
        updateTaskRxDb({
          id: tmpTask.id,
          changes: {
            ...tmpTask,
            subtasks: tmpSubtasks,
            modified_at: getDbStrDateFull(),
          },
        })
      );
      onSubmit();
    }
  };

  const updateSingleInstance = () => {
    getUpdateSingleInstanceActions(
      taskDay,
      newRecurringOwnFields,
      {
        ...tmpTask,
        subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
      },
      taskOrRecurring as RxRecurringNaked,
      recurringOccurrencesForCurrent
    ).forEach((action) => {
      dispatch(action);
    });
    onSubmit();
  };

  const updateFutureInstances = () => {
    getUpdateFutureRecurringActions(
      taskDay,
      newRecurringOwnFields,
      {
        ...tmpTask,
        subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
      },
      taskOrRecurring as RxRecurringNaked,
      recurringOccurrencesForCurrent
    ).forEach((action) => {
      dispatch(action);
    });
    onSubmit();
  };

  return {
    isShowRecurringUpdatePromptModal,
    setIsShowRecurringUpdateModal,
    tmpTask,
    tmpSubtasks,
    updateTmpTask,
    afterTaskEditSubmit,
    updateSingleInstance,
    updateFutureInstances,
    updateAllRecurring,
    setTmpSubtasks,
    newRecurringOwnFields,
    setNewRecurringOwnFields,
  };
};
