import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { type RootState } from "../store";
import { getDB } from "../../rxdb/db";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import { useSelector } from "react-redux";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { getRecurringForDayWithoutDetached } from "../../util/getRecurringForDayWithoutDetached";
import { RecurringType } from "../../features/TaskEditForm/taskEdit.model";

export interface RecurringState {
  recurringCfgs: RxRecurringNaked[];
}

const initialState: RecurringState = {
  recurringCfgs: [],
};

const WEEKDAYS: (keyof RxRecurringNaked)[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const recurringModelClean = <T extends Partial<RxRecurringNaked>>(
  originalRecurringOrPartial: T
): T => {
  if (
    originalRecurringOrPartial &&
    originalRecurringOrPartial.recurring_type === RecurringType.Weekly &&
    WEEKDAYS.every((weekday) => originalRecurringOrPartial[weekday] === false)
  ) {
    console.warn("No weekdays selected – not saving");
    const cleanModel = {
      ...originalRecurringOrPartial,
      interval: originalRecurringOrPartial.interval ?? 1,
      monday: true,
    };

    return cleanModel;
  }

  return originalRecurringOrPartial;
};

export const addRecurringRxDb = createAsyncThunk(
  "recurring/addRecurringRxDb",
  async (recurring: RxRecurringNaked) => {
    const db = await getDB();

    try {
      await db.recurring.insert(recurringModelClean(recurring));
    } catch (e) {
      console.error(e);
    }
  }
);

export const removeRecurringRxDb = createAsyncThunk(
  "recurring/removeRecurringRxDb",
  async (id: string) => {
    const db = await getDB();
    try {
      const doc = await db.recurring.findOne(id).exec();
      if (doc) {
        try {
          await doc.remove();
        } catch (err) {
          console.error("could not remove doc");
          console.dir(err);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const updateRecurringRxDb = createAsyncThunk(
  "recurring/updateRecurringRxDb",
  async ({
    id,
    changes,
  }: {
    id: string;
    changes: Partial<RxRecurringNaked>;
  }) => {
    const db = await getDB();
    try {
      const doc = await db.recurring.findOne(id).exec();
      if (doc) {
        try {
          await doc.incrementalPatch(
            recurringModelClean({
              ...changes,
              // important to set
              modified_at: getDbStrDateFull(),
            })
          );
        } catch (err) {
          console.error("could not update doc");
          console.dir(err);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
);

export const recurringSlice = createSlice({
  name: "recurring",
  initialState,
  reducers: {
    setRecurringCfgs: (state, action: PayloadAction<RxRecurringNaked[]>) => {
      state.recurringCfgs = action.payload;
    },
  },
  // extraReducers: (builder) => {},
});

export const { setRecurringCfgs } = recurringSlice.actions;

export const selectRecurringCfgs = (state: RootState): RxRecurringNaked[] =>
  state.recurring.recurringCfgs;

const selectRecurringCfgsForDayWithoutDetached = (
  state: RootState,
  dayDate: Date
): RxRecurringNaked[] => {
  const recurringOccurrences = state.recurringOccurrence.recurringOccurrences;

  return getRecurringForDayWithoutDetached(
    state.recurring.recurringCfgs,
    recurringOccurrences,
    getIsoStrDateOnly(dayDate)
  ).sort(
    (recurringCfgA, recurringCfgB) =>
      (recurringCfgA.start_time ?? 0) - (recurringCfgB.start_time ?? 0)
  );
};

export const useRecurringCfgsForDayWithoutDetached = (dayDate: Date) => {
  return useSelector((state: RootState): RxRecurringNaked[] => {
    return selectRecurringCfgsForDayWithoutDetached(state, dayDate);
  });
};

export const useRecurringById = (recurringId: string) => {
  return useSelector((state: RootState): RxRecurringNaked => {
    const r = state.recurring.recurringCfgs.find((rI) => rI.id === recurringId);
    if (!r) {
      throw new Error("Could not find recurringCfg");
    }
    return r;
  });
};

export default recurringSlice.reducer;
