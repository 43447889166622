import { useTimelineEntriesForDay } from "../../../store/task/taskSlice";
import React, { useEffect, useState } from "react";
import { TimelineTask } from "./TimelineTask/TimelineTask";
import { type TimelineEntry, TimelineEntryType } from "./timeline.model";
import styles from "./Timeline.module.scss";
import { TimelineAddTaskPrompt } from "./TimelineAddTaskPrompt/TimelineAddTaskPrompt";
import { TimelineGapMsg } from "./TimelineGapMsg/TimelineGapMsg";
import { TimelineTimes } from "./TimelineTimes/TimelineTimes";
import { lazySetInterval } from "../../../util/lazySetInterval";

// TODO set to one minute for prod
const RE_RENDER_TIMELINE_INTERVAL = 10 * 1000;

export const Timeline = (props: { day: Date }) => {
  const [now, setNow] = useState(new Date());
  const timelineEntries: TimelineEntry[] = useTimelineEntriesForDay(
    props.day,
    now
  );

  useEffect(() => {
    return lazySetInterval(() => {
      setNow(new Date());
    }, RE_RENDER_TIMELINE_INTERVAL);
  }, []);

  return (
    <div className={styles.Timeline}>
      {timelineEntries.map((e, index) => (
        <div key={index} className={styles.timelineRow}>
          <TimelineTimes
            entry={e}
            isIncludeEndTime={
              e.type === TimelineEntryType.TASK && e.durationInMin >= 15
            }
          />

          {e.type === TimelineEntryType.TASK && <TimelineTask entry={e} />}
          {e.type === TimelineEntryType.ADD_TASK_PROMPT && (
            <TimelineAddTaskPrompt entry={e} />
          )}
          {e.type === TimelineEntryType.GAP_MSG && <TimelineGapMsg entry={e} />}
        </div>
      ))}
    </div>
  );
};
