import React, { useEffect, useState } from "react";
import "./App.scss";
import { supabase } from "./api/supabaseClient";
import Auth from "./features/User/Auth";
import Account from "./features/User/Account";
import { addRxPlugin } from "rxdb";
import { setTasks } from "./store/task/taskSlice";
import { useAppDispatch, useAppSelector } from "./shared/hooks";
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { getDB } from "./rxdb/db";
import { startReplicationForAll } from "./rxdb/replication";
import { DayView } from "./features/DayView/DayView";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { selectSession, setSession } from "./store/session/sessionSlice";
import { Ico } from "./ui-components/Ico";
import {
  selectIsShowSidebar,
  toggleIsShowAddTaskModal,
} from "./store/ui/uiSlice";
import { Settings } from "./features/Settings/Settings";
import { setRecurringCfgs } from "./store/recurring/recurringSlice";
import { GlobalModals } from "./features/GlobalModals/GlobalModals";
import { setRecurringOccurrences } from "./store/recurringOccurrence/recurringOccurrenceSlice";
import { TermsOfService } from "./features/TermsOfService/TermsOfService";
import { LS_KEY } from "./shared/app.const";
import { OnboardingQuestions } from "./features/OnboardingQuestions/OnboardingQuestions";
import { type RootState } from "./store/store";
import { Sidebar } from "./features/Sidebar/Sidebar";
import { TaskInbox } from "./features/TaskInbox/TaskInbox";

// TODO only exec during development
addRxPlugin(RxDBDevModePlugin);
// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
Modal.setAppElement("#root");

let isInit = false;
let replicationAll: any[];

function App() {
  const session = useSelector(selectSession);
  const isShowSidebar = useSelector(selectIsShowSidebar);
  const dispatch = useAppDispatch();

  const [isTermsOfServiceAccepted, setIsTermsOfServiceAccepted] = useState(
    !!localStorage.getItem(LS_KEY.TermsOfServiceAccepted)
  );
  const [isLoadTasksOnce, setIsLoadTasksOnce] = useState(false);
  const [isLoadRecurringOnce, setIsLoadRecurringOnce] = useState(false);
  const [isShowOnboardingQuestions, setIsShowOnboardingQuestions] =
    useState(false);

  const [isLoadRecurringOccurrencesOnce, setIsLoadRecurringOccurrencesOnce] =
    useState(false);
  const isLoadedAllRxDBModels =
    isLoadTasksOnce && isLoadRecurringOnce && isLoadRecurringOccurrencesOnce;
  const hasAnyTasks = useAppSelector(
    (state: RootState) =>
      state.recurring.recurringCfgs.length > 0 || state.task.tasks.length > 0
  );

  const checkReSync = (session) => {
    if (session && replicationAll) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      replicationAll.forEach((repl) => repl.reSync());
    }
  };

  useEffect(() => {
    // NOTE: because of React.StrictMode this might be executed twice, which we want to avoid

    if (!isInit) {
      isInit = true;

      getDB()
        .then((db) => {
          db.task
            .find({ sort: [{ created_at: "asc" }] })
            .$.subscribe((documents) => {
              const mappedClean = documents.map((d) => d.toMutableJSON());
              void dispatch(setTasks(mappedClean));
              if (!isLoadTasksOnce) setIsLoadTasksOnce(true);
            });

          db.recurring.find({ sort: [] }).$.subscribe((documents) => {
            const mappedClean = documents.map((d) => d.toMutableJSON());
            void dispatch(setRecurringCfgs(mappedClean));
            console.log(mappedClean);

            if (!isLoadRecurringOnce) setIsLoadRecurringOnce(true);
          });

          db.recurring_occurrence
            .find({ sort: [] })
            .$.subscribe((documents) => {
              const mappedClean = documents.map((d) => d.toMutableJSON());
              void dispatch(setRecurringOccurrences(mappedClean));
              if (!isLoadRecurringOccurrencesOnce)
                setIsLoadRecurringOccurrencesOnce(true);
            });

          startReplicationForAll(db)
            .then((all) => {
              console.log(all);
              replicationAll = all as any[];
            })
            .catch(console.error);
        })
        .catch((err) => {
          console.error("DB INIT FAILED");
          console.error(err);
        });

      void supabase.auth.getSession().then(({ data: { session } }) => {
        dispatch(setSession(session));
        // check to resync for all models on after login
        checkReSync(session);
      });

      supabase.auth.onAuthStateChange((_event, session) => {
        dispatch(setSession(session));
        // check to resync for all models on after login
        checkReSync(session);
      });
    }
  }, []);

  // LOADING
  if (!isLoadedAllRxDBModels) {
    return <div>Loading</div>;
  }

  // NOT LOGGED IN
  if (!session) {
    return (
      <Modal isOpen={!session} className="A">
        <Auth />
      </Modal>
    );
  }

  // LOGGED IN, BUT TOS NOT ACCEPTED
  if (!isTermsOfServiceAccepted) {
    return (
      <Modal isOpen={true} className="A">
        <TermsOfService
          onAccept={() => {
            setIsTermsOfServiceAccepted(true);
            if (!hasAnyTasks) {
              setIsShowOnboardingQuestions(true);
            }
          }}
        />
      </Modal>
    );
  }

  // LOGGED IN, BUT NO TASKS YET
  // TODO dont always show
  if (isShowOnboardingQuestions) {
    return (
      <Modal isOpen={true} className="A">
        <OnboardingQuestions
          onComplete={() => setIsShowOnboardingQuestions(false)}
        />
      </Modal>
    );
  }

  const SIDEBAR_WIDTH = 280;
  // STANDARD PAGE
  return (
    <div className="App">
      <Sidebar width={isShowSidebar ? SIDEBAR_WIDTH : 0}>
        <TaskInbox />
      </Sidebar>

      <div
        className="main-container"
        style={{ marginLeft: isShowSidebar ? SIDEBAR_WIDTH : 0 }}
      >
        <div className="nav-btns">
          <Settings />
          <Account key={session.user.id} session={session} />
        </div>

        <GlobalModals />

        <button
          className="btn btn-float"
          onClick={() => {
            void dispatch(toggleIsShowAddTaskModal());
          }}
        >
          <Ico name="add" className="iContrast" />
        </button>

        <DayView />
      </div>
    </div>
  );
}

export default App;
