import { useState } from "react";
import { LS_KEY } from "../../shared/app.const";

export const TermsOfService = ({ onAccept }: { onAccept: () => void }) => {
  const [isTermsOfServiceDenied, setIsTermsOfServiceDenied] = useState(false);

  return (
    <>
      <h2>Terms of Service</h2>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aut
        blanditiis cum cupiditate eaque inventore iste quas sequi sint tempore!
        Beatae cupiditate debitis earum eius ipsum iure, magnam maiores
        molestiae neque nesciunt nostrum officia quae, quisquam, similique
        suscipit tempore voluptatum!
      </p>
      <p>
        Adipisci aliquid animi ipsa maiores, minus nemo quos repellendus soluta
        suscipit temporibus! Ea odio optio pariatur porro, quidem velit! Cum
        dicta dignissimos esse eum fugit incidunt ipsum itaque labore libero nam
        nisi nobis quia quibusdam quis reprehenderit sed, sequi voluptatum?
      </p>
      <p>
        Alias beatae dolorum, eligendi et fuga ipsam, itaque iure molestias nam
        odio officia optio perferendis praesentium quam recusandae reprehenderit
        tempore? Amet, commodi consequuntur delectus dolorem in ipsum nihil nisi
        nulla omnis, placeat porro quisquam quod sapiente sit suscipit. Minima,
        nemo.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque
        explicabo quod reprehenderit. A, amet delectus, ea eius eos harum, ipsam
        iure mollitia nam non nostrum obcaecati officiis praesentium quae quasi
        quis quo sapiente sequi sit sunt temporibus velit veritatis voluptatem.
        Amet delectus dignissimos eligendi excepturi libero nihil odit omnis
        porro quisquam rem repellendus, sapiente tempora tempore ut vel vitae!
      </p>

      {isTermsOfServiceDenied && (
        <p>
          <strong>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Please note that we can't provide our services to you without
            accepting our terms of use.
          </strong>
        </p>
      )}

      <button
        className="btn-neutral"
        onClick={() => {
          localStorage.setItem(LS_KEY.TermsOfServiceAccepted, "true");
          onAccept();
        }}
      >
        I accept
      </button>
      <button
        className="btn-neutral"
        onClick={() => setIsTermsOfServiceDenied(true)}
      >
        I dont accept
      </button>
    </>
  );
};
