import React, { useState } from "react";
import styles from "./AddTaskModal.module.scss";
import { useSelector } from "react-redux";
import { TaskEditForm } from "../TaskEditForm/TaskEditForm";
import { useAppDispatch } from "../../shared/hooks";
import {
  type RecurringOwnFields,
  type TmpSubtask,
  type TmpTask,
} from "../TaskEditForm/taskEdit.model";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import { TMP_REV } from "../../rxdb/commonDB.const";
import { addTaskRxDb, type Task } from "../../store/task/taskSlice";
import { getDbStrDateFull } from "../../util/getDbStrDateFull";
import { getNewTmpSubtask } from "../TaskEditForm/getNewTmpSubtask";
import { selectUserIdOrError } from "../../store/session/sessionSlice";
import Modal from "react-modal";
import classNames from "classnames";
import { Ico } from "../../ui-components/Ico";
import {
  DEFAULT_TASK_DURATION,
  DEFAULT_TASK_SYMBOL_ID,
  TaskType,
} from "../../shared/app.const";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { addRecurringRxDb } from "../../store/recurring/recurringSlice";
import { getColor } from "../../util/getColor";
import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { selectAddTaskModalDefaults } from "../../store/ui/uiSlice";

const getNewTmpTask = (): TmpTask => ({
  _deleted: false,
  id: uuid(),
  title: "",
  type: TaskType.normal,
  symbol: DEFAULT_TASK_SYMBOL_ID,
  is_symbol_set: false,
  color: "#E6A19AFF",
  day: getIsoStrDateOnly(new Date()),
  start_time: null,
  timezone: null,
  duration: DEFAULT_TASK_DURATION,
  is_all_day: false,
  is_in_inbox: false,
  note: "",
  order_index: null,
  calendar_day_index: 1,
  is_reminder_detached: false,
  is_hidden: false,
  completed_at: null,
  replication_revision: TMP_REV,
  alerts: [],
  events: [],
  subtasks: [],
});

export function AddTaskModal(props: {
  onClose: () => void;
  copiedTaskOrRecurring?: Task | RxRecurringNaked;
}) {
  const isCopyTask = !!props.copiedTaskOrRecurring;
  const userId = useSelector(selectUserIdOrError);
  const addTaskModalDefaults = useSelector(selectAddTaskModalDefaults);
  // Doesn't make much sense to have until suggestions are there
  // const [isTitleGivenOnce, setIsTitleGivenOnce] = useState<boolean>(isCopyTask);
  const [isTitleGivenOnce, setIsTitleGivenOnce] = useState<boolean>(true);
  const [recurringCfg, setRecurringCfg] = useState<RecurringOwnFields | null>(
    null
  );
  const dispatch = useAppDispatch();

  const tmpTaskRR = addTaskModalDefaults
    ? { ...getNewTmpTask(), ...addTaskModalDefaults }
    : getNewTmpTask();

  const [tmpTask, setTmpTask] = useState<TmpTask>(
    props.copiedTaskOrRecurring
      ? {
          ...tmpTaskRR,
          ...props.copiedTaskOrRecurring,
          id: tmpTaskRR.id,
          replication_revision: tmpTaskRR.replication_revision,
          _deleted: false,
        }
      : tmpTaskRR
  );
  const [tmpSubtasks, setTmpSubtasks] = useState<TmpSubtask[]>(
    // NOTE: if subtasks for taskOrRecurring are missing we fall back to the default
    props.copiedTaskOrRecurring?.subtasks
      ? (props.copiedTaskOrRecurring.subtasks as TmpSubtask[])
      : [getNewTmpSubtask()]
  );

  const updateTmpTask = (updateProps: Partial<TmpTask>) => {
    setTmpTask({
      ...tmpTask,
      ...updateProps,
    });
  };
  const createTask = () => {
    if (!tmpTask.id) {
      throw new Error(
        "Tmp taskOrRecurring key is needed to created taskOrRecurring with sub tasks"
      );
    }
    if (tmpTask.title.length <= 1) {
      console.warn("Task title length should at least be 1");
      // TODO inform user somehow
      return;
    }

    console.log("createTask()", tmpTask, tmpSubtasks, recurringCfg);

    if (recurringCfg) {
      void dispatch(
        addRecurringRxDb({
          ...recurringCfg,
          ...tmpTask,
          user_id: userId,
          created_at: getDbStrDateFull(),
          modified_at: getDbStrDateFull(),
          subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
        })
      );
    } else {
      void dispatch(
        addTaskRxDb({
          ...tmpTask,
          user_id: userId,
          created_at: getDbStrDateFull(),
          modified_at: getDbStrDateFull(),
          subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
        })
      );
    }

    setTmpSubtasks([getNewTmpSubtask()]);
    setTmpTask(getNewTmpTask());
    props.onClose();
  };

  return (
    <Modal
      isOpen={true}
      className={classNames({
        [styles.AddTaskModal]: true,
        NoOverFlowModal: true,
        [styles.isFixed]: !isCopyTask,
      })}
      onRequestClose={() => props.onClose()}
      shouldCloseOnOverlayClick={true}
    >
      <header>
        {isCopyTask ? "Copy " : "Create"} <em>Task</em>
      </header>
      <div onClick={props.onClose} className="modalCloseBtn">
        <Ico name="close" />
      </div>

      <div
        className={styles.contentWrapper}
        style={
          {
            "--primary-color": getColor(tmpTask.color),
            "--btn-bg-color": getColor(tmpTask.color),
          } as any
        }
      >
        {isTitleGivenOnce ? (
          <TaskEditForm
            onSubmit={() => createTask()}
            tmpTask={tmpTask}
            tmpSubtasks={tmpSubtasks}
            recurringCfg={recurringCfg}
            onUpdateTmpTask={setTmpTask}
            onUpdateTmpSubtasks={setTmpSubtasks}
            onUpdateRecurringCfg={setRecurringCfg}
          />
        ) : (
          <>
            <div className="medium-label">What?</div>
            <form
              className={styles.taskTitleForm}
              onSubmit={(ev) => {
                setIsTitleGivenOnce(true);
              }}
            >
              <div className={styles.taskTitleBox}>
                <input
                  type="text"
                  autoFocus
                  placeholder="Some task yeah"
                  minLength={1}
                  value={tmpTask.title}
                  onChange={(ev) => updateTmpTask({ title: ev.target.value })}
                  name="taskTitle"
                />
              </div>

              <button type="submit" className={"btn " + styles.submitBtn}>
                Continue
              </button>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
}
