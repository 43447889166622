import styles from "./TimelineTask.module.scss";
import React from "react";
import { TaskCheckbox } from "./TaskCheckbox/TaskCheckbox";
import { useAppDispatch } from "../../../../shared/hooks";
import classNames from "classnames";
import { Ico } from "../../../../ui-components/Ico";
import { formatTaskTimeframe } from "../../../../util/formatTaskTimeframe";
import { type TimelineTaskEntry } from "../timeline.model";
import { getMaterialIdFromSymbolId } from "../../../../util/getMaterialIdFromSymbolId";
import { DEFAULT_TASK_MATERIAL_ICON } from "../../../../shared/app.const";
import { setContextModalTask } from "../../../../store/ui/uiSlice";
import { isRecurringTask } from "../../../../util/isRecurringTask";
import { type RxRecurringOccurrenceNaked } from "../../../../rxdb/recurringOccurrence.schema";
import { useRecurringOccurrenceForDayByRecurringId } from "../../../../store/recurringOccurrence/recurringOccurrenceSlice";
import { useToggleCompletedAt } from "../../../../shared/taskRecurringWrapper/useToggleCompletedAt";
import { getColor } from "../../../../util/getColor";
import { type RxTaskNaked } from "../../../../rxdb/task.schema";

export const TimelineTask = (props: { entry: TimelineTaskEntry }) => {
  const taskOrRecurring = props.entry.taskOrRecurring;
  const subtasks = taskOrRecurring.subtasks ?? [];
  const isRecurring = isRecurringTask(taskOrRecurring);
  const dispatch = useAppDispatch();
  const taskDay: Date = props.entry.start;
  const recurringOccurrenceForDay: RxRecurringOccurrenceNaked | null =
    useRecurringOccurrenceForDayByRecurringId(taskDay, taskOrRecurring.id);
  const { toggleCompletedAt } = useToggleCompletedAt(
    taskDay,
    taskOrRecurring,
    recurringOccurrenceForDay ?? undefined
  );

  const completedAt = recurringOccurrenceForDay
    ? recurringOccurrenceForDay.completed_at
    : (taskOrRecurring as RxTaskNaked).completed_at ?? null;

  const nrOfDoneSubtasks = isRecurring
    ? recurringOccurrenceForDay?.subtask_occurrences
      ? recurringOccurrenceForDay.subtask_occurrences.filter(
          (sto) => !!sto.completed_at
        ).length
      : 0
    : subtasks.filter((s) => s.completed_at).length;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const calcTaskHeight = (duration: number): number => {
    const BASE = 56;
    const minDuration = 15;
    const maxDuration = 120;
    const maxMultiple = 4;
    const a: number = (BASE * (maxMultiple - 1)) / (maxDuration - minDuration);
    const b: number =
      BASE -
      ((BASE * (maxMultiple - 1)) / (maxDuration - minDuration)) * minDuration;
    const result: number = a * duration + b;
    return Math.min(Math.max(result, BASE), BASE * maxMultiple);
  };

  return (
    <div
      className={classNames(styles.TimelineTask, {
        [styles.isCompleted]: !!(taskOrRecurring as RxTaskNaked).completed_at,
        [styles.isOverlapBefore]: props.entry.isOverlapBefore,
        [styles.isOverlapAfter]: props.entry.isOverlapAfter,
        [styles.isFullProgress]: props.entry.progress === 100,
      })}
      style={
        {
          "--task-color": getColor(taskOrRecurring.color),
          "--progress": props.entry.progress.toString() + "%",
        } as any
      }
    >
      <div
        onClick={() =>
          dispatch(
            setContextModalTask({
              taskOrRecurring,
              dayTimestamp: taskDay.getTime(),
            })
          )
        }
        className={classNames(styles.taskIcon)}
        style={{
          height: calcTaskHeight(taskOrRecurring.duration ?? 0),
        }}
      >
        <Ico
          name={
            getMaterialIdFromSymbolId(taskOrRecurring.symbol) ??
            DEFAULT_TASK_MATERIAL_ICON
          }
          className="iContrast"
        />

        {props.entry.progress > 0 && (
          <div className={classNames([styles.taskIconInverse])}>
            <Ico
              name={
                getMaterialIdFromSymbolId(taskOrRecurring.symbol) ??
                DEFAULT_TASK_MATERIAL_ICON
              }
            />
          </div>
        )}
      </div>

      <div
        className={styles.taskMiddleSection}
        onClick={() =>
          dispatch(
            setContextModalTask({
              taskOrRecurring,
              dayTimestamp: taskDay.getTime(),
            })
          )
        }
      >
        {props.entry.isOverlapBefore && (
          <div className={styles.overlappingMsg}>
            Tasks are <em>overlapping</em>
          </div>
        )}
        <div
          title={JSON.stringify(taskOrRecurring, null, 2)}
          className={styles.taskTime}
        >
          {/* TODO check if formatting works for recurring */}
          {formatTaskTimeframe(taskOrRecurring as RxTaskNaked, taskDay)}{" "}
          {isRecurring && <Ico name="replay" className="izXs" />}
        </div>

        <div className={styles.taskTitle}>{taskOrRecurring.title}</div>
        {subtasks.length > 0 && (
          <div className={styles.taskSubtask}>
            <Ico name="check_box" className="izI" /> {nrOfDoneSubtasks}/
            {subtasks.length}
          </div>
        )}
      </div>
      <TaskCheckbox
        onChange={() => toggleCompletedAt()}
        checked={!!completedAt}
      />
    </div>
  );
};
