// avoids the performance issues caused by normal set interval, when the user
// is not at the computer for some time
export const lazySetInterval = (
  func: () => void,
  intervalDuration: number
): (() => void) => {
  let lastTimeoutId: number;

  const interval = (): void => {
    lastTimeoutId = window.setTimeout(interval, intervalDuration);
    func();
  };

  lastTimeoutId = window.setTimeout(interval, intervalDuration);

  return () => {
    window.clearTimeout(lastTimeoutId);
  };
};
