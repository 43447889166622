import styles from "./Settings.module.scss";
import { Ico } from "../../ui-components/Ico";
import classNames from "classnames";
import { useState } from "react";
import Modal from "react-modal";

export const Settings = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <button
        className={classNames([styles.settingsBtn, "btn", "btn-ico"])}
        onClick={() => setIsModalOpen(true)}
      >
        <Ico name="settings" />
      </button>
      <Modal
        isOpen={isModalOpen}
        className="A"
        onRequestClose={() => setIsModalOpen(false)}
      >
        <header>Settings</header>
        <div onClick={() => setIsModalOpen(false)} className="modalCloseBtn">
          <Ico name="close" />
        </div>
      </Modal>
    </>
  );
};
