import {
  type ExtractDocumentTypeFromTypedRxJsonSchema,
  type RxJsonSchema,
  toTypedRxJsonSchema,
} from "rxdb";
import { DATE_STR_LENGTH } from "./commonDB.const";

export const RECURRING_SCHEMA_LITERAL = {
  title: "recurring schema",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    // STANDARD REPLICATION FIELDS
    id: {
      type: "string",
      minLength: 8,
      maxLength: 100,
    },
    replication_revision: {
      type: "string",
      minLength: 8,
    },
    received_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    // OTHER STANDARD FIELDS
    user_id: {
      type: "string",
    },
    created_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    modified_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    _deleted: {
      type: "boolean",
    },

    // OTHER FIELDS
    title: {
      type: "string",
    },
    type: {
      type: "number",
    },
    symbol: {
      type: "string",
    },
    is_symbol_set: {
      type: "boolean",
    },
    color: {
      type: "string",
    },
    start_time: {
      type: ["number", "null"],
    },
    timezone: {
      type: ["string", "null"],
    },
    duration: {
      type: ["integer", "null"],
    },
    is_all_day: {
      type: "boolean",
    },
    note: {
      type: "string",
    },
    subtasks: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          title: {
            type: "string",
          },
          completed_at: {
            type: ["string", "null"],
          },
        },
      },
    },
    alerts: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          type: {
            type: "string",
          },
          time: {
            type: "number",
          },
          offset: {
            type: ["integer", "null"],
          },
        },
      },
    },

    recurring_type: {
      type: "integer",
    },
    interval: {
      type: "integer",
    },
    start_day: {
      type: "string",
    },
    end_day: {
      type: ["string", "null"],
    },
    monday: {
      type: ["boolean", "null"],
    },
    tuesday: {
      type: ["boolean", "null"],
    },
    wednesday: {
      type: ["boolean", "null"],
    },
    thursday: {
      type: ["boolean", "null"],
    },
    friday: {
      type: ["boolean", "null"],
    },
    saturday: {
      type: ["boolean", "null"],
    },
    sunday: {
      type: ["boolean", "null"],
    },
  },

  required: [
    // ...TASK_SCHEMA.required
    "id",
    "title",
    "type",
    "user_id",
    "replication_revision",
    "created_at",
    "modified_at",
    "symbol",
    "is_symbol_set",
    "color",
    "duration",
    "is_all_day",
    "note",
    // NOTE: we make all fields required, to make our life easier with the server communication
    // they are usually not required, but get a default value on the server so read model is practically
    // different from write model, but this gets confusing
    // NOTE2: received_at is the only exception, since we should avoid ever setting this on the client
    // "received_at",
    "_deleted",
    "start_time",
    "timezone",
    "subtasks",
    "alerts",

    // OWN FIELDS
    // NOTE: we make all fields required, to make our life easier with the server communication
    // they are usually not required, but get a default value on the server so read model is practically
    // different from write model, but this gets confusing
    "recurring_type",
    "start_day",
    "end_day",
    "interval",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ],
  indexes: [],
} as const;

const SCHEMA_TYPED = toTypedRxJsonSchema(RECURRING_SCHEMA_LITERAL);
export type RxRecurringNaked = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof SCHEMA_TYPED
>;
// TODO as any stuff required until merged: https://github.com/pubkey/rxdb/pull/4579
export const RECURRING_SCHEMA: RxJsonSchema<RxRecurringNaked> =
  RECURRING_SCHEMA_LITERAL as any as RxJsonSchema<RxRecurringNaked>;
