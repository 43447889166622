import { type RxRecurringNaked } from "../../rxdb/recurring.schema";
import { type RxRecurringOccurrenceNaked } from "../../rxdb/recurringOccurrence.schema";
import { updateRecurringRxDb } from "../../store/recurring/recurringSlice";
import { getIsoStrDateOnly } from "../../util/getIsoStrDateOnly";
import { getDayBeforeDate } from "../../util/getDayBeforeDate";
import { removeRecurringOccurrencesRxDb } from "../../store/recurringOccurrence/recurringOccurrenceSlice";

export const getDeleteFutureRecurringActions = (
  taskDay: Date,
  originalRecurring: RxRecurringNaked,
  recurringOccurrencesForCurrent: RxRecurringOccurrenceNaked[]
): any[] => {
  const dayBeforeDate = getDayBeforeDate(taskDay);
  const actions: any[] = [
    updateRecurringRxDb({
      id: originalRecurring.id,
      changes: {
        // NOTE only end_day is updated!!!
        end_day: getIsoStrDateOnly(dayBeforeDate),
      },
    }),
  ];
  const recurringOccurrencesToDelete = recurringOccurrencesForCurrent.filter(
    (occurrence) => new Date(occurrence.day) >= taskDay
  );

  if (recurringOccurrencesToDelete.length > 0) {
    actions.push(
      removeRecurringOccurrencesRxDb(
        recurringOccurrencesToDelete.map((occurrence) => occurrence.id)
      )
    );
  }

  return actions;
};
