import { type Task } from "../store/task/taskSlice";
import { humanizeDuration } from "./humanizeDuration";
import { dbStartTimeAndDateToTimeStr } from "./dbStartTimeAndDateToTimeStr";
import { humanizeDate } from "./humanizeDate";
import { getDbStrDateFull } from "./getDbStrDateFull";

export const formatTaskTimeframe = (t: Task, day: Date): string => {
  if (t.is_all_day) {
    return `${humanizeDate(day)} (All-Day)`;
  }
  const strDate = getDbStrDateFull(day);

  const startTime = t.start_time
    ? dbStartTimeAndDateToTimeStr(t.start_time, strDate)
    : "";
  const tDuration = t.duration ?? 0;
  const endTime =
    t.start_time && tDuration > 1
      ? ` - ${dbStartTimeAndDateToTimeStr(
          t.start_time + tDuration / 60,
          strDate
        )}`
      : "";
  const timeWindow = `${startTime}${endTime}`;
  const duration = tDuration > 1 ? ` (${humanizeDuration(tDuration)})` : "";
  return `${timeWindow}${duration}`;
};
