import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../store";
import { type Session } from "@supabase/supabase-js";

export interface SessionState {
  session: null | Session;
}

const initialState: SessionState = {
  session: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session | null>) => {
      state.session = action.payload;
    },
  },
});

export const { setSession } = sessionSlice.actions;

export const selectSession = (state: RootState): Session | null =>
  state.session.session;
export const selectSessionOrError = (state: RootState): Session => {
  if (!state.session.session) {
    throw new Error("No session available");
  }
  return state.session.session;
};
export const selectUserIdOrError = (state: RootState): string => {
  if (!state.session.session?.user.id) {
    throw new Error("No user available");
  }
  return state.session.session.user.id;
};

export default sessionSlice.reducer;
export const sessionReducer = sessionSlice.reducer;
