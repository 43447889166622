import {
  type Action,
  configureStore,
  type ThunkAction,
} from "@reduxjs/toolkit";
import taskReducer from "./task/taskSlice";
import uiReducer from "./ui/uiSlice";
import recurringReducer from "./recurring/recurringSlice";
import recurringOccurrenceReducer from "./recurringOccurrence/recurringOccurrenceSlice";
import { sessionReducer } from "./session/sessionSlice";

export const store = configureStore({
  reducer: {
    task: taskReducer,
    ui: uiReducer,
    recurring: recurringReducer,
    recurringOccurrence: recurringOccurrenceReducer,
    session: sessionReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
